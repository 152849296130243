import axios from 'axios'
import envData from '@/env.js'
export const env = envData[envData.state];

import { EmitBus } from '@/emit-bus'

export const authApi = axios.create({
    baseURL: `${env.auth_url}/api`,
    headers: {
      'api_token': env.api_token
    }
});

authApi.interceptors.response.use(function (response) {
  return response
}, function(error) {
    if(error.response && error.response.status===401){
      EmitBus.$emit("unauthorized", error.response)
    }else if(error.response && error.response.status===403){
      EmitBus.$emit("forbidden", error.response)
    }
  return Promise.reject(error)
})

export const factcelebApi = axios.create({
  baseURL: `${env.factceleb_url}/api`,
  headers: {
    'api_token': env.api_token
  }
});

export const imdbApi = axios.create({
  baseURL: 'https://www.omdbapi.com/'
})

factcelebApi.interceptors.response.use(function (response) {
return response
}, function(error) {
  if(error.response && error.response.status===401){
    EmitBus.$emit("unauthorized", error.response)
  }else if(error.response && error.response.status===403){
    EmitBus.$emit("forbidden", error.response)
  }
return Promise.reject(error)
})