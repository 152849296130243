module.exports = {
    state: 'production',
    development: {
        app_key: process.env.VUE_APP_APP_KEY,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        my_folder: process.env.VUE_APP_FILE_MY_FOLDER,
        api_token: process.env.VUE_APP_API_TOKEN,
        file_path: process.env.VUE_APP_FILE_PATH,
        factceleb_url: process.env.VUE_APP_FACTCELEB_URL
    },
    test: {
        app_key: process.env.VUE_APP_APP_KEY,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        my_folder: process.env.VUE_APP_FILE_MY_FOLDER,
        api_token: process.env.VUE_APP_API_TOKEN,
        file_path: process.env.VUE_APP_FILE_PATH,
        factceleb_url: process.env.VUE_APP_FACTCELEB_URL
    },
    production: {
        app_key: process.env.VUE_APP_APP_KEY,
        auth_url: process.env.VUE_APP_AUTH_URL,
        file_url: process.env.VUE_APP_FILE_URL,
        my_folder: process.env.VUE_APP_FILE_MY_FOLDER,
        api_token: process.env.VUE_APP_API_TOKEN,
        file_path: process.env.VUE_APP_FILE_PATH,
        factceleb_url: process.env.VUE_APP_FACTCELEB_URL
    }
}