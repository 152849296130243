<template>
  <v-form ref="form" v-model="valid" @submit.prevent="save">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card
              class="primary white--text header-bar-content"
              style="margin-top:10px;"
            >
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"
                  ><v-icon class="cursor white--text"
                    >mdi-arrow-left</v-icon
                  ></v-btn
                >
                <span>Create Person</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">
                  <v-checkbox v-model="form.celebrity" label="Celebrity" />

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                  ></v-text-field>

                  <file-manager
                    v-model="form.photo"
                    width="200"
                    height="200"
                    cropWidth="300"
                    cropHeight="300"
                    :allowed="['png', 'jpg', 'jpeg']"
                    :multiple="false"
                    label="Photo"
                  />

                  <!-- <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.gender"
                    hint="Please enter gender"
                    label="Gender"
                  ></v-text-field> -->

                  <v-select
                    class="mt-2"
                    :items="gender_items"
                    v-model="form.gender"
                    label="Gender"
                  ></v-select>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.date_of_birth"
                    hint="Please enter date of birth"
                    :label="`Date of birth ${formatData('date_of_birth')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.dob"
                    hint="Please enter dob"
                    label="Date of birth (text)"
                  ></v-text-field>

                  <v-select
                    class="mt-2"
                    :items="marital_status_items"
                    v-model="form.marital_status"
                    label="Marital status"
                  ></v-select>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.date_of_married"
                    hint="Please enter date of married"
                    :label="`Date of married ${formatData('date_of_married')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.dom"
                    hint="Please enter dom"
                    label="Date of married (text)"
                  ></v-text-field>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.date_of_death"
                    hint="Please enter date of death"
                    :label="`Date of death ${formatData('date_of_death')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.dod"
                    hint="Please enter dod"
                    label="Date of death (text)"
                  ></v-text-field>

                  <v-textarea
                    name="textarea"
                    v-model="form.cause_of_death"
                    label="Cause of death"
                    rows="2"
                  ></v-textarea>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="cancel"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      color="success"
                      class="mt-5 px-5"
                      right
                      rounded
                      :disabled="!valid"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Person from "@/api/person";
import fileManager from "@/components/file-manager";
import ObjectHelper from "@/helper/object";
import moment from "moment";

export default {
  components: {
    fileManager,
  },
  data() {
    return {
      marital_status_items: [
        { text: "Choose", value: "" },
        { text: "Single", value: "single" },
        { text: "Married", value: "married" },
        { text: "Divorced", value: "divorced" },
        { text: "Engaged", value: "engaged" },
        { text: "In Relationship", value: "in-relationship" },
        { text: "Widow", value: "widow" },
        { text: "Widower", value: "widower" },
      ],
      gender_items: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
        { text: "Transgender", value: "Transgender" },
        { text: "Other", value: "Other" },
      ],
      valid: null,
      form: {
        celebrity: true,
        name: null,
        slug: null,
        photo: null,
        gender: null,
        marital_status: null,
        date_of_married: null,
        dom: null,
        date_of_birth: null,
        dob: null,
        date_of_death: null,
        dod: null,
        cause_of_death: null,
      },
      rules: {
        name: [(v) => !!v || "Name is required"],
        slug: [(v) => !!v || "Slug is required"],
      },
    };
  },
  watch: {
    "form.name"(value) {
      this.form.slug = value
        .trim()
        .toLowerCase()
        .replace(/[."“”‘’,()&_%~@#:<>{}+-`+=[\]]/g, "")
        .replaceAll(" ", "-")
        .replace(/-{2,}/g, "-");
    },
  },
  methods: {
    formatData(type) {
      if (!this.form[type]) return "";

      return "(" + moment(this.form[type]).format("D MMM YYYY") + ")";
    },
    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.spinner(true);
          let { data } = await Person.create(
            ObjectHelper.emptyStringToNull(this.form)
          );
          this.snackbar({
            status: true,
            message: data.message,
            color: "success",
          });
          this.spinner(false);
          this.$router.push({ name: "Person" });
        } catch (err) {
          this.snackbar({
            status: true,
            message: err.response.data.message,
            color: "error",
          });
          this.spinner(false);
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Person" });
    },
  },
};
</script>
