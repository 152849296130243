<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Profile</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field v-model="user.email" label="Email" disabled></v-text-field>

                  <v-text-field 
                    v-model="user.display_name" 
                    :rules="rules.displayName"
                    label="Display As*"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.first_name"
                    :rules="rules.first_name"
                    hint="Please enter first name"
                    counter="15"
                    label="First Name"
                  ></v-text-field>

                  <v-text-field
                    class="mt-4"
                    v-model="user.middle_name"
                    :rules="rules.middle_name"
                    hint="Please enter middle name"
                    counter="15"
                    label="Middle Name"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.last_name"
                    :rules="rules.last_name"
                    hint="Please enter last name"
                    counter="15"
                    label="Last Name"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="phone"
                    hint="Please enter phone number"
                    counter="14"
                    label="Phone"
                    @keyup.prevent="formatPhoneNumber"
                  ></v-text-field>

                  <file-manager
                    v-model="user.avatar"
                    width="200"
                    height="200" 
                    :allowed="['png', 'jpg', 'jpeg']" 
                    :multiple="false" 
                    :crop="false" 
                     />

                  <v-text-field
                    class="mt-2"
                    v-model="formatDateTime"
                    label="Created At"
                    disabled
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash";
import dateHelper from '@/helper/date'
import phoneHelper from '@/helper/phone'
import User from '@/api/user'
import { mapGetters, mapActions } from "vuex";
import fileManager from '@/components/file-manager'
export default {
  components: {
    fileManager
  },
  data() {
    return {
      user: {
        display_name: "",
        email: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        avatar: "",
        createdAt: ""
      },
      rules: {
        displayName: [
          v => !!v || "Display name is required",
          v => (v || "").length >= 5 || "Min 5 characaters"
        ],
        first_name: [v => (v || "").length <= 15 || "Max 15 characaters"],
        middle_name: [v => (v || "").length <= 15 || "Max 15 characaters"],
        last_name: [v => (v || "").length <= 15 || "Max 15 characaters"]
      },
      phone: ''
    };
  },
  mounted() {
    this.user = _.pick(this.vg_loginUser, [
      "display_name",
      "email",
      "first_name",
      "middle_name",
      "last_name",
      "avatar",
      "phone",
      "created_at"
    ]);
    this.phone = phoneHelper.nepaliPhoneFormat(this.user.phone)
  },
  computed: {
    formatDateTime: {
      get() {
        return dateHelper.formatDateTime(this.user.created_at)
      }
    },
    ...mapGetters({
      vg_loginUser: "login/user",
    })
  },
  methods: {
    formatPhoneNumber() {
        this.user.phone = phoneHelper.flatPhoneFormat(this.phone)
        this.phone = phoneHelper.nepaliPhoneFormat(this.phone)
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await User.update(this.vg_loginUser.id, this.user)
          this.va_user(this.vg_loginUser.id)
          this.snackbar({status: true, message: data.message, color: 'success'})
          this.spinner(false)
          this.$router.push({name: 'Dashboard'})
        }catch(err){
          console.log(err)
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Dashboard" });
    },
    ...mapActions({
      va_user: 'login/user'
    })
  }
};
</script>