<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Gossip</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" class="mt-4" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn
            v-if="role.trashes"
            class="ml-4 mt-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_gossip.rows"
          :loading="loading"
          :server-items-length="vg_gossip.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
          :footer-props="{'items-per-page-options':[20, 30, 50, 100, -1]}"
        >
          <template v-slot:item.image="{ item }">
            <v-img class="ma-1" v-if="item.image" :src="item.image" width="80" height="50" />
            <v-icon class="ma-1" style="width:80px; height:50px; border: solid thin #ccc;" v-else large>mdi-account</v-icon>
          </template>
          <template v-slot:item.title="{ item }">
            <span v-if="item.status=='sponsored' || (item.person && item.person.length>0)">{{item.title}}</span>
            <span class="error--text" v-else>Person Not Found</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip small :color="color[item.status]" dark>
              {{item.status}}
            </v-chip>
          </template>
          <template v-slot:item.updating="{ item }">
            <v-chip v-if="item.updating" small :color="color[item.updating]" dark>
              {{item.updating_by}}
            </v-chip>
          </template>
          <template v-slot:item.published_at="{ item }">
            <span v-if="item.published_at">{{formatDate(item.published_at)}}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <span>{{formatDate(item.updated_at)}}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.update && (!item.updating_by || (item.updating_by && item.updating_id==vg_user.id))" color="primary" class="ml-2 mr-1" @click.prevent="editItemInNewWindow(item)"
              >mdi-application-edit</v-icon
            >
            <v-icon v-if="role.update && (!item.updating_by || (item.updating_by && item.updating_id==vg_user.id))" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete && (!item.updating_by || (item.updating_by && item.updating_id==vg_user.id))" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash"
import moment from 'moment'
import { mapGetters, mapMutations, mapActions } from "vuex";
import GossipApi from "@/api/gossip"
import VuetifyTable from "@/helper/table"
export default {
  data() {
    return {
      loading: true,
      color: {
        assigned: "#F44336",
        progress: "#2196F3",
        completed: "#4CAF50",
        published: "#9E9E9E",
        sponsored: "#9C27B0",
        true: "#F44336",
        false: "#9E9E9E"
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Image", value: "image" },
        { text: "Title", value: "title" },
        { text: "Status", value: "status" },
        { text: "Updating", value: "updating" },
        { text: "Assign To", value: "assign_to", width: '130px' },
        { text: "Published At", value: "published_at", width: '130px' },
        { text: "Updated At", value: "updated_at", width: '130px'},
        { text: "Actions", value: "action", sortable:false, width:'140px' },
      ],
      search: ""
    };
  },
  mounted(){
    this.options.search=''
    window.scrollTo({ top: 0})
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          await this.va_gossip(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    "vg_queryObject.search"(){
      this.options.page = 1
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Gossip")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_user: 'login/user',
      vg_gossip: "gossip/list",
      vg_queryObject: "gossip/query"
    })
  },
  methods: {
    formatDate(date){
      return moment(date).format('D MMM YYYY, h:mm a')
    },
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    createApi() {
      this.$router.push({ name: "GossipCreate" })
    },
    editItem(item) {
      this.$router.push({ name: "GossipEdit", params: {slug: item.slug} })
    },
    editItemInNewWindow(item){
      let route = this.$router.resolve({name: 'GossipEdit', params: {slug: item.slug}})
      window.open(route.href, '_blank')
    },
    trashedApi() {
      this.$router.push({ name: "GossipTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm("Are you sure to delete this?").then(async () => {
        try{
          self.spinner(true)
          let {data} = await GossipApi.delete(item.id)
          this.snackbar({status: true, message: data.message, color:"success"})
          await this.va_gossip(this.optionsToQueryString(this.vg_queryObject))
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_queryObject: "gossip/query"
    }),
    ...mapActions({
      va_gossip: "gossip/list"
    })
  }
};
</script>
