<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Movie</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" class="mt-4" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn
            v-if="role.trashes"
            class="ml-4 mt-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_movie.rows"
          :loading="loading"
          :server-items-length="vg_movie.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
          :footer-props="{'items-per-page-options':[20, 30, 50, 100, -1]}"
        >
          <template v-slot:item.name="{item}">
              <v-img style="display:inline-block; vertical-align:middle;" v-if="item.image" :src="item.image" height="50" width="50" contain />
              <v-icon style="width:50px;" v-else large>mdi-image-area</v-icon>
              <div class="ml-2" style="display:inline-block; vertical-align:middle;">{{item.name}}</div>
          </template>
          <template v-slot:item.genre="{ item }">
            <div v-if="item.genre && item.genre.length>0">
              <v-chip class="mr-1 mb-1" v-for="each, genInd in item.genre" :key="genInd" small>{{each.name}}</v-chip>
            </div>
            <span class="error--text" v-else>Genre trashed</span>
          </template>
          <template v-slot:item.year="{ item }">
            <div v-if="item.release_date">{{item.release_date}}</div>
            <div v-else>{{item.year}}</div>
          </template>
          <template v-slot:item.action="{ item }">
            <div style="width:132px;">
              <v-icon v-if="seasonRole" color="primary" class="ml-2 mr-1" @click.prevent="season(item)"
                >mdi-television</v-icon
              >
              <v-icon v-if="castRole" color="primary" class="ml-2 mr-1" @click.prevent="cast(item)"
                >mdi-face-recognition</v-icon
              >
              <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash"
import { mapGetters, mapMutations, mapActions } from "vuex";
import MovieApi from "@/api/movie"
import VuetifyTable from "@/helper/table"
export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Genre", value: "genre" },
        { text: "Year", value: "year" },
        { text: "Rating", value: "rating" },
        { text: "Mpa", value: "mpa" },
        { text: "Actions", value: "action", sortable:false },
      ],
      search: ""
    };
  },
  mounted(){
    this.options.search=''
    window.scrollTo({ top: 0})
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          await this.va_movie(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    "vg_queryObject.search"(){
      this.options.page = 1
    }
  },
  computed: {
    castRole(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Cast")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    seasonRole(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Season")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Movie")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    ...mapGetters({
      vg_movie: "movie/list",
      vg_groupRole: "login/role",
      vg_queryObject: "movie/query"
    })
  },
  methods: {
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    createApi() {
      this.$router.push({ name: "MovieCreate" })
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "MovieEdit" })
    },
    trashedApi() {
      this.$router.push({ name: "MovieTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm("Are you sure to delete this?").then(async () => {
        try{
          self.spinner(true)
          let {data} = await MovieApi.delete(item.id)
          this.snackbar({status: true, message: data.message, color:"success"})
          await this.va_movie(this.optionsToQueryString(this.vg_queryObject))
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    season(item){
      this.vm_seasonQueryObject(this.queryStringToOptions('items=15&page=1&search='+item.name))
      this.$router.push({name: 'Season'})
    },
    cast(item){
      this.vm_castQueryObject(this.queryStringToOptions('items=15&page=1&search='+item.name))
      this.$router.push({name: 'Cast'})
    },
    ...mapMutations({
      vm_editObj: "movie/editObj",
      vm_queryObject: "movie/query",
      vm_seasonQueryObject: "season/query",
      vm_castQueryObject: "cast/query"
    }),
    ...mapActions({
      va_movie: "movie/list"
    })
  }
};
</script>
