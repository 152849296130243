import Tag from "@/views/tag";
import TagCreate from "@/views/tag/create"
import TagEdit from "@/views/tag/edit"
import TagTrash from "@/views/tag/trash"

export default [
    {
        name: "Tag",
        path: "/tag",
        component: Tag,
        meta: {
            nav: {
                addToNav: true,
                navText: "Tag",
                header: "Tag",
                navIcon: "mdi-tag",
            }
        },
    },
    {
        name: "TagCreate",
        path: "/tag/create",
        component: TagCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Tag > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "TagEdit",
        path: "/tag/edit",
        component: TagEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Tag > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "TagTrash",
        path: "/tag/trash",
        component: TagTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Tag > Trash",
                navIcon: "",
            }
        }
    }
]
