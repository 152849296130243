<template>
    <v-dialog v-model="openIframe" persistent max-width="450">
        <v-card>
            <v-toolbar elevation="0" dark class="secondary">
                <v-toolbar-title>Add Video</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeIframe">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <v-form ref="form" v-model="valid" @submit.prevent="addIframe">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.url" label="URL" :rules="linkRule" hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn :disabled="!valid" type="submit" class="primary" block>Insert Link</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['openIframe', 'iframeAttrs'],
    data(){
        return {
            valid: false,
            linkRule: [
                v => !!v || 'Required',
                v => /^https?:\/\/(www\.)?\w+\.\w{2,5}(\.\w{2,3})?(\/.*)?$/.test(v) || 'Url must be valid'
            ],
            form: {
                url: ''
            }
        }
    },
    watch: {
        openIframe(val){
            if(val===true && this.form.url!==''){
                this.$refs.form.reset()
            }
        }
    },
    methods: {
        closeIframe(){
            this.$emit('closeIframe')
        },
        addIframe(){
            this.$emit('addIframe', this.form)
        }
    }
}
</script>