<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
                <span>Create Cast</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">

                  <movie class="mt-2" v-model.trim="form.movie" :rules="rules.movie" label="Movie*"  />

                  <person class="mt-2" v-model.trim="form.person" :rules="rules.person" label="Person*"  />

                  <v-select
                    class="mt-2"
                    :items="role_items"
                    v-model="form.role"
                    :rules="rules.role"
                    label="Role*"
                  ></v-select>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.character"
                    :rules="rules.character"
                    hint="Please enter character"
                    label="Character"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Cast from "@/api/cast"
import Movie from '@/components/Movie'
import Person from '@/components/Person'

export default {
  components: {
    Movie,
    Person
  },
  data() {
    return {
      role_items:['cast','director','writer'],
      valid: null,
      form: {
        movie: null,
        person: null,
        role: null,
        character: null,
      },
      rules: {
        movie: [
          v => !!v || "Movie is required",
        ],
        person: [
          v => !!v || "Person is required",
        ],
        role: [
          v => !!v || "Role is required",
        ],
        character: [
        ],
      }
    };
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Cast.create(this.form)
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Cast"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Cast" });
    }
  }
};
</script>