var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-input"},[_c('div',{staticClass:"v-input__control"},[_c('label',{staticClass:"v-label v-label--active theme--light mt-6 mb-2",domProps:{"textContent":_vm._s(_vm.label)}}),_c('div',{staticClass:"editor"},[(_vm.editor)?_c('div',{staticClass:"editor-bar"},[(_vm.id == 'description')?[_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('bold') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .wrapInList('heading', { level: 3 })
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-home")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('bold') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleBold()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-bold")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('italic') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleItalic()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-italic")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('underline') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleUnderline()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-underline")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('subscript') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleSubscript()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-subscript")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('superscript') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleSuperscript()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-superscript")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('blockquote') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleBlockquote()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-quote-left")])],1)]:_vm._e(),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('orderedList') ? 'primary' : ''},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-list-ol")])],1),(_vm.id == 'description')?[_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('bulletList') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleBulletList()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-list-ul")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('heading', { level: 1 }) ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .setHeading({ level: 1 })
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-heading")]),_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v("1")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('heading', { level: 2 }) ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .setHeading({ level: 2 })
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-heading")]),_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v("2")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('heading', { level: 3 }) ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .setHeading({ level: 3 })
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-heading")]),_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v("3")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('heading', { level: 4 }) ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .setHeading({ level: 4 })
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-heading")]),_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v("4")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","color":_vm.editor.isActive('paragraph') ? 'primary' : ''},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .setParagraph()
                .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-paragraph")])],1)]:_vm._e(),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","disabled":!_vm.editor.can().undo()},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .undo()
              .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-undo")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","disabled":!_vm.editor.can().redo()},on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .redo()
              .run()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-redo")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){$event.preventDefault();return _vm.openLinkPop.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-link")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":"","disabled":!_vm.editor.isActive('link')},on:{"click":function($event){$event.preventDefault();return _vm.removeLink.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-unlink")])],1),(_vm.id == 'description')?[_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){$event.preventDefault();return _vm.openFileManager.apply(null, arguments)}}},[_c('v-icon',[_vm._v("fas fa-image")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){$event.preventDefault();return _vm.openIframePop.apply(null, arguments)}}},[_c('v-icon',[_vm._v("fas fa-video")])],1),_c('br'),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .insertTable({ rows: 4, cols: 2 })
                .run()}}},[_c('v-icon',[_vm._v("mdi-table")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleHeaderColumn()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-arrow-left")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleHeaderRow()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-arrow-up")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .addColumnBefore()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-column-plus-before")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .addColumnAfter()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-column-plus-after")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .deleteColumn()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-column-remove")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .toggleHeaderRow()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-row")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .addRowBefore()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-row-plus-before")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .addRowAfter()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-row-plus-after")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .deleteRow()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-row-remove")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .mergeCells()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-merge-cells")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .splitCell()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-split-cell")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .deleteTable()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-remove")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.editor
                .chain()
                .focus()
                .fixTables()
                .run()}}},[_c('v-icon',[_vm._v("mdi-table-check")])],1)]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"editor-content",class:_vm.id})]),(_vm.editor && _vm.editor.getText())?_c('div',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.editor.getText().match(/\S+/g).length)+" words ")]):_vm._e(),_c('link-pop',{attrs:{"openLink":_vm.openLink,"linkAttrs":_vm.linkAttrs},on:{"closeLink":_vm.closeLinkPop,"addLink":_vm.addLink}}),_c('iframe-pop',{attrs:{"openIframe":_vm.openIframe,"iframeAttrs":_vm.iframeAttrs},on:{"closeIframe":_vm.closeIframePop,"addIframe":_vm.addIframe}}),_c('manager',{attrs:{"openManager":_vm.openManager,"multiple":_vm.multiple},on:{"closeManger":_vm.closeManager,"selected":_vm.selectedFile}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }