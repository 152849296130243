import Season from "@/views/season";
import SeasonCreate from "@/views/season/create"
import SeasonEdit from "@/views/season/edit"
import SeasonTrash from "@/views/season/trash"

export default [
    {
        name: "Season",
        path: "/season",
        component: Season,
        meta: {
            nav: {
                addToNav: true,
                navText: "Season",
                header: "Season",
                navIcon: "mdi-television",
            }
        },
    },
    {
        name: "SeasonCreate",
        path: "/season/create",
        component: SeasonCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Season > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "SeasonEdit",
        path: "/season/edit",
        component: SeasonEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Season > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "SeasonTrash",
        path: "/season/trash",
        component: SeasonTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Season > Trash",
                navIcon: "",
            }
        }
    }
]
