var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"shaped":"","elevation":"6"}},[_c('v-card',{staticClass:"primary white--text header-bar-content",staticStyle:{"margin-top":"10px"}},[_c('v-card-title',{staticClass:"px-6"},[_c('v-btn',{staticClass:" mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{staticClass:"cursor white--text"},[_vm._v("mdi-arrow-left")])],1),_c('span',[_vm._v("Person Trash")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-9",attrs:{"dark":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-4 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vg_trashApps,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('v-img',{staticStyle:{"display":"inline-block","vertical-align":"middle"},attrs:{"src":item.photo,"height":"50","width":"50","contain":""}}):_c('v-icon',{staticStyle:{"width":"50px"},attrs:{"large":""}},[_vm._v("mdi-account")]),_c('div',{staticClass:"ml-2",staticStyle:{"display":"inline-block","vertical-align":"middle"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.date_of_birth",fn:function(ref){
var item = ref.item;
return [(item.date_of_birth)?_c('div',[_vm._v(_vm._s(item.date_of_birth))]):_c('div',[_vm._v(_vm._s(item.dob))])]}},{key:"item.date_of_married",fn:function(ref){
var item = ref.item;
return [(item.date_of_married)?_c('div',[_vm._v(_vm._s(item.date_of_married))]):_c('div',[_vm._v(_vm._s(item.dom))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.role.restore)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.restoreItem(item)}}},[_vm._v("mdi-reload")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }