var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"shaped":"","elevation":"6"}},[_c('v-card',{staticClass:"primary white--text header-bar-content",staticStyle:{"margin-top":"10px"}},[_c('v-card-title',{staticClass:"px-6"},[_c('span',[_vm._v("Movie")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-9",attrs:{"dark":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),(_vm.role.add)?_c('v-btn',{staticClass:"mt-4",attrs:{"color":"white black--text","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.createApi.apply(null, arguments)}}},[_vm._v("Add")]):_vm._e(),(_vm.role.trashes)?_c('v-btn',{staticClass:"ml-4 mt-4",attrs:{"color":"red darken-4 grey--text text--lighten-2 font-weight-bold","rounded":""},on:{"click":_vm.trashedApi}},[_vm._v("Trash")]):_vm._e()],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-4 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vg_movie.rows,"loading":_vm.loading,"server-items-length":_vm.vg_movie.count,"options":_vm.options,"search":_vm.options.search,"items-per-page":_vm.options.itemsPerPage,"footer-props":{'items-per-page-options':[20, 30, 50, 100, -1]}},on:{"update:options":function($event){_vm.options=$event},"update:search":function($event){return _vm.$set(_vm.options, "search", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('v-img',{staticStyle:{"display":"inline-block","vertical-align":"middle"},attrs:{"src":item.image,"height":"50","width":"50","contain":""}}):_c('v-icon',{staticStyle:{"width":"50px"},attrs:{"large":""}},[_vm._v("mdi-image-area")]),_c('div',{staticClass:"ml-2",staticStyle:{"display":"inline-block","vertical-align":"middle"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.genre",fn:function(ref){
var item = ref.item;
return [(item.genre && item.genre.length>0)?_c('div',_vm._l((item.genre),function(each,genInd){return _c('v-chip',{key:genInd,staticClass:"mr-1 mb-1",attrs:{"small":""}},[_vm._v(_vm._s(each.name))])}),1):_c('span',{staticClass:"error--text"},[_vm._v("Genre trashed")])]}},{key:"item.year",fn:function(ref){
var item = ref.item;
return [(item.release_date)?_c('div',[_vm._v(_vm._s(item.release_date))]):_c('div',[_vm._v(_vm._s(item.year))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"132px"}},[(_vm.seasonRole)?_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.season(item)}}},[_vm._v("mdi-television")]):_vm._e(),(_vm.castRole)?_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.cast(item)}}},[_vm._v("mdi-face-recognition")]):_vm._e(),(_vm.role.update)?_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.role.delete)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }