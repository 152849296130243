var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"shaped":"","elevation":"6"}},[_c('v-card',{staticClass:"primary white--text header-bar-content",staticStyle:{"margin-top":"10px"}},[_c('v-card-title',{staticClass:"px-6"},[_c('span',[_vm._v("Timeline Trash")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-9",attrs:{"dark":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-4 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vg_trashApps,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.person",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.person.name))])]}},{key:"item.linked_person",fn:function(ref){
var item = ref.item;
return [(item.linked_person)?_c('span',[_vm._v(_vm._s(item.linked_person.name))]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('div',[_vm._v(_vm._s(item.date))]):_c('div',[_vm._v(_vm._s(item.doe))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.role.restore)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.restoreItem(item)}}},[_vm._v("mdi-reload")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }