import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import env from '@/env.js'

import login from './modules/login'
import category from './modules/category'
import person from './modules/person'
import person_detail from './modules/person-detail'
import relation from './modules/relation'
import timeline from './modules/timeline'
import biography from './modules/biography'
import gossip from './modules/gossip'
import genre from './modules/genre'
import movie from './modules/movie'
import season from './modules/season'
import cast from './modules/cast'
import tag from './modules/tag'

export const config = env[env.state];
var ls = new SecureLS({ isCompression: false, encryptionSecret: config.app_key })
Vue.use(Vuex)

export default  new Vuex.Store({
    plugins: [createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })],
    modules: {login, category, person, biography, gossip, person_detail, relation, timeline, genre, movie, season, cast, tag}
})