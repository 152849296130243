import Movie from "@/views/movie";
import MovieCreate from "@/views/movie/create"
import MovieEdit from "@/views/movie/edit"
import MovieTrash from "@/views/movie/trash"

export default [
    {
        name: "Movie",
        path: "/movie",
        component: Movie,
        meta: {
            nav: {
                addToNav: true,
                navText: "Movie",
                header: "Movie",
                navIcon: "mdi-movie",
            }
        },
    },
    {
        name: "MovieCreate",
        path: "/movie/create",
        component: MovieCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Movie > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "MovieEdit",
        path: "/movie/edit",
        component: MovieEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Movie > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "MovieTrash",
        path: "/movie/trash",
        component: MovieTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Movie > Trash",
                navIcon: "",
            }
        }
    }
]
