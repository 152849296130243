<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="12" >
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
                <span>Create Biography</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10" offset-md="1">
                  <!-- Tab -->
                  <v-tabs v-model="tab">
                    <v-tab>General</v-tab>
                    <v-tab>Description</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <!-- First tab -->
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12" md="6">
                          <group-checkbox 
                            class="mt-5"
                            :items="categories" 
                            v-model="form.category_ids"
                            :rules="rules.category_ids"
                            label="Category*" 
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                          <person class="mt-2" v-model="form.person" :rules="rules.person" label="Person*"  />

                          <v-select
                            class="mt-2"
                            :items="status_items"
                            v-model="form.status"
                            :rules="rules.status"
                            label="Status*"
                          ></v-select>

                          <user class="mt-2" v-model="form.assign_to" label="Assign to"  />
                        </v-col>
                      </v-row>

                      <v-text-field
                        type="text"
                        class="mt-2"
                        v-model="form.title"
                        :rules="rules.title"
                        hint="Please enter title"
                        label="Title*"
                      ></v-text-field>

                      <v-text-field
                        type="text"
                        class="mt-2"
                        v-model="form.slug"
                        :rules="rules.slug"
                        hint="Please enter slug"
                        label="Slug*"
                      ></v-text-field>

                      <h2 class="primary--text">Body Measurement</h2>

                      <v-row>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.height"
                            :rules="rules.height"
                            hint="Please enter height"
                            label="Height"
                            suffix="meter (m)"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.weight"
                            :rules="rules.weight"
                            hint="Please enter weight"
                            label="Weight"
                            suffix="kg"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.waist_size"
                            :rules="rules.waist_size"
                            hint="Please enter waist size"
                            label="Waist size"
                            suffix="inch"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="text"
                            class="mt-2"
                            v-model="form.shoe_size"
                            hint="Please enter shoe size"
                            label="Shoe size"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <h2 class="primary--text">Male</h2>
                      <v-row>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.bicep_size"
                            :rules="rules.bicep_size"
                            hint="Please enter bicep size"
                            label="Bicep size"
                            suffix="inch"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.chest_size"
                            :rules="rules.chest_size"
                            hint="Please enter chest size"
                            label="Chest size"
                            suffix="inch"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <h2 class="primary--text">Female</h2>
                      <v-row>
                        <v-col>
                          <v-text-field
                            type="text"
                            class="mt-2"
                            v-model="form.hair_color"
                            hint="Please enter hair color"
                            label="Hair color"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col>
                          <v-text-field
                            type="text"
                            class="mt-2"
                            v-model="form.eye_color"
                            hint="Please enter eye color"
                            label="Eye color"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.bra_size"
                            :rules="rules.bra_size"
                            hint="Please enter bra size"
                            label="Bra size"
                            suffix="inch"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.breast_size"
                            :rules="rules.breast_size"
                            hint="Please enter breast size"
                            label="Bust size"
                            suffix="inch"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-text-field
                            type="text"
                            class="mt-2"
                            v-model="form.cup_size"
                            hint="Please enter cup size"
                            label="Cup size"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            type="number"
                            class="mt-2"
                            v-model.number="form.hip_size"
                            :rules="rules.hip_size"
                            hint="Please enter hip size"
                            label="Hip size"
                            suffix="inch"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-text-field
                            type="text"
                            class="mt-2"
                            v-model="form.dress_size"
                            hint="Please enter dress size"
                            label="Dress size"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                    </v-tab-item>
                    <!-- Second Tab -->
                    <v-tab-item>
                      <tag v-model="form.tags" label="Tag"  />

                      <editor id="description" :loaded="true" v-model="form.description" label="Description" />

                      <editor id="reference" :loaded="true" v-model="form.reference" label="Reference" />

                      <v-textarea
                        class="mt-3"
                        outlined
                        name="textarea"
                        v-model="form.meta_content"
                        label="Meta content"
                      ></v-textarea>
                      <v-progress-linear
                        rounded
                        :background-color="getProgress('meta', 'color')+' lighten-3'"
                        :color="getProgress('meta', 'color')"
                        :value="getProgress('meta')"
                        style="position:relative; top:-30px; margin-left:5px; margin-right:5px; width:98%"
                      ></v-progress-linear>
                    </v-tab-item>
                  </v-tabs-items>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import moment from 'moment'
import Tag from '@/components/Tag'
import Editor from '@/components/editor'
import Person from '@/components/Person'
import User from '@/components/User'
import Biography from "@/api/biography"
import CategoryAPI from '@/api/category'
import GroupCheckbox from '@/components/GroupCheckbox'
import ObjectHelper from '@/helper/object'
import {mapGetters, mapMutations} from 'vuex'

export default {
  components: {
    Tag,
    User,
    Person,
    Editor,
    GroupCheckbox
  },
  data() {
    return {
      status_items:[
        {text: 'Assigned', value: 'assigned'},
        {text: 'Progress', value: 'progress'},
        {text: 'Completed', value: 'completed'},
        {text: 'Published', value: 'published'},
        {text: 'Sponsored', value: 'sponsored'}
      ],
      valid: null,
      categories: [],
      form: {
        category_ids: [],
        person: null,
        status: null,
        title: null,
        slug: null,
        height: null,
        weight: null,
        chest_size: null,
        bicep_size: null,
        waist_size: null,
        hip_size: null,
        bra_size: null,
        breast_size: null,
        cup_size: null,
        shoe_size: null,
        dress_size: null,
        hair_color: null,
        eye_color: null,
        tags: [],
        description: null,
        reference: null,
        meta_content: null,
        assign_to: null,
        created_by: null,
        published_by: null,
        published_at: null
      },
      rules: {
        category_ids: [
          v => !!v || "Category is required"
        ],
        person: [
          v => !!v || "Person is required",
        ],
        
        status: [
          v => !!v || "Status is required",
        ],
        title: [
          v => !!v || "Title is required",
        ],
        slug: [
          v => !!v || "Slug is required",
        ],
        height: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        weight: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        chest_size: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        bicep_size: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        waist_size: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        hip_size: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        bra_size: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ],
        breast_size: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=999 || "Must be less than or equals to 999",
        ]
      }
    };
  },
  async mounted(){
    let {data} = await CategoryAPI.list('type=biography')
    this.categories = data.payload.rows.map(each => {return {id: each.id, name: each.name}})
  },
  watch: {
    'form.title'(value){
      this.form.slug = value.trim().toLowerCase().replace(/[."“”‘’,()&_%~@#:<>{}+-`+=[\]]/g, "").replaceAll(" ", "-").replace(/-{2,}/g, '-')
    }
  },
  computed: {
    tab: {
      get(){
        return this.vg_tab
      },
      set(val){
        this.vm_tab(val)
      }
    },
    ...mapGetters({
      vg_tab: 'biography/tab',
      vg_user: 'login/user'
    })
  },
  methods: {
    getProgress(field, type=''){
      // get field
      let minAllowed = 155
      let maxAllowed = 250
      let value = (this.form.meta_content)?this.form.meta_content.length:''

      // get color or value
      if(type=='color'){
        if(value<=minAllowed){
          return 'grey'
        }else if(value<=maxAllowed){
          return 'green'
        }else{
          return 'red'
        }
      }else{
        return parseInt(value/maxAllowed*100)
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          this.form.created_by = this.vg_user.id
          if(this.form.status == 'published' || this.form.status == 'sponsored'){
            this.form.published_by = this.vg_user.id
            this.form.published_at = moment()
          }
          let {data} = await Biography.create(ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Biography"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Biography" });
    },
    ...mapMutations({
      vm_tab: 'biography/tab'
    })
  }
};
</script>