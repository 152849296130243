<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
                <span>Create Season</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">

                  <movie class="mt-2" v-model.trim="form.movie" :rules="rules.movie" label="Movie*"  />

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.title"
                    :rules="rules.title"
                    hint="Please enter title"
                    label="Title"
                  ></v-text-field>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.release_date"
                    hint="Please enter release date"
                    :label="`Release date ${formatData('release_date')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.rd"
                    :rules="rules.rd"
                    hint="Please enter rd"
                    label="Release data (text)"
                  ></v-text-field>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.no_of_episode"
                    :rules="rules.no_of_episode"
                    hint="Please enter no of episode"
                    label="No of episode"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Season from "@/api/season"
import Movie from '@/components/Movie'
import ObjectHelper from '@/helper/object'
import moment from 'moment'

export default {
  components: {
    Movie
  },
  data() {
    return {
      valid: null,
      form: {
        movie: null,
        name: null,
        title: null,
        release_date: null,
        rd: null,
        no_of_episode: null,
      },
      rules: {
        movie: [
          v => !!v || "Movie is required",
          v => (typeof v=='object') || 'Select movie from drop down'
        ],
        name: [
          v => !!v || "Name is required",
        ],
        title: [
        ],
        release_date: [
        ],
        rd: [
        ],
        no_of_episode: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=9000 || "Must be less than or equals to 9000",
        ],
      }
    };
  },
  methods: {
    formatData(type){
      if(!this.form[type]) return ''

      return '('+moment(this.form[type]).format('D MMM YYYY')+')'
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Season.create(ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Season"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Season" });
    }
  }
};
</script>