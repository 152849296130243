<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
                <span>Create Relation</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">

                  <person class="mt-2" v-model.trim="form.person" :rules="rules.person" label="Person*"  />

                  <person class="mt-2" v-model.trim="form.linked_person" :rules="rules.linked_person" label="Linked Person*"  />

                  <!-- <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.relation"
                    :rules="rules.relation"
                    hint="Please enter relation"
                    label="Relation (link person is relation of person)*"
                  ></v-text-field> -->

                  <v-autocomplete
                    v-model="form.relation"
                    :rules="rules.relation"
                    label="Relation (link person is relation of person)*"
                    :items="relations"
                  ></v-autocomplete>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.date_of_start"
                    hint="Please enter date of start"
                    :label="`Date of start ${formatData('date_of_start')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.dos"
                    :rules="rules.dos"
                    hint="Please enter dos"
                    label="Date of start (text)"
                  ></v-text-field>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.date_of_end"
                    hint="Please enter date of end"
                    :label="`Date of end ${formatData('date_of_end')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.doe"
                    :rules="rules.doe"
                    hint="Please enter doe"
                    label="Date of end (text)"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Person from '@/components/Person'
import Relation from "@/api/relation"
import ObjectHelper from '@/helper/object'
import moment from 'moment'

export default {
  components: {
    Person
  },
  data() {
    return {
      relations: [
        'Older brother', 'Younger brother', 'Brother', 'Older sister', 'Younger sister', 'Sister', 'Cousin', 'Mother', 'Father', 'Husband', 'Wife', 'Ex-husband', 'Ex-wife',
        'Son', 'Daughter', 'Grandson', 'Granddaughter', 'Fiancé', 'Fiancée', 'Partner', 'Ex-partner', 'Boyfriend', 'Girlfriend', 'Grandfather', 'Grandmother', 'Uncle', 'Aunt', 'Niece', 'Nephew',
        'Ex-boyfriend', 'Ex-girlfriend', 'Rumored boyfriend', 'Rumored girlfriend', 'Godparent', 'Adoptive mother', 'Adoptive father', 'Adopted brother', 'Adopted sister', 'Adopted son', 'Adopted daughter', 
        'Half-brother', 'Half-sister', 'Half-sibling', 'Step-brother', 'Step-sister','Step-father', 'Step-mother', 'Step-sibling', 'Step-son', 'Step-daughter', 'Late husband', 'Late wife',
        'Twin brother', 'Twin sister', 'Fiancée', 'Ex-fiancée', 'Fiancé', 'Ex-fiancé'
      ],
      valid: null,
      form: {
        person: null,
        linked_person: null,
        relation: null,
        date_of_start: null,
        dos: null,
        date_of_end: null,
        doe: null,
      },
      rules: {
        person: [
          v => !!v || "Person is required",
          v => (typeof v=='object') || 'Select person from drop down'
        ],
        linked_person: [
          v => !!v || "Linked person is required",
          v => (typeof v=='object') || 'Select person from drop down'
        ],
        relation: [
          v => !!v || "Relation is required",
        ],
        date_of_start: [
        ],
        dos: [
        ],
        date_of_end: [
        ],
        doe: [
        ],
      }
    };
  },
  methods: {
    formatData(type){
      if(!this.form[type]) return ''

      return '('+moment(this.form[type]).format('D MMM YYYY')+')'
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Relation.create(ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Relation"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Relation" });
    }
  }
};
</script>