import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import '@/plugins/middleware'
import '@/plugins/mixin'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data(){
    return {
      LeftNav: true,
      Spinner: false,
      Snackbar: {
        status: false,
        message: "",
        timeout: 2000,
        color: ""
      },
      Confirm: {
        status: false,
        message: "",
        response: null
      }
    }
  },
}).$mount('#app')
