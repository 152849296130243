<template>
    <div class="v-input">
        <div class="v-input__control">
            <v-combobox
                v-model="selected"
                :items="items"
                :search-input.sync="search"
                :label="label"
                item-value="id"
                item-text="display_name"
                hide-selected
                 >
            </v-combobox>
            <v-text-field class="d-none" v-model="selected" :rules="rules" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import UserApi from '@/api/user'
export default {
    props: ['value', 'label', 'rules'],
    data(){
        return {
            selected: null,
            search: '',
            items: [],
        }
    },
    watch: {
        value(value){
            if(value){
                this.selected = value
            }else{
                this.selected = null
            }
        },
        selected(values){
            this.$emit('input', values)
        },
        search: _.debounce(async function(val){
            if(val){
                await this.getUserByName(val)
            }
        }, 900)
    },
    methods: {
        async getUserByName(userName){
            let {data} = await UserApi.list('items=15&page=1&search='+userName)
            this.searched = true
            this.items =data.payload.rows
        }
    }
}
</script>