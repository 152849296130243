import Gossip from "@/views/gossip";
import GossipCreate from "@/views/gossip/create"
import GossipEdit from "@/views/gossip/edit"
import GossipTrash from "@/views/gossip/trash"

export default [
    {
        name: "Gossip",
        path: "/gossip",
        component: Gossip,
        meta: {
            nav: {
                addToNav: true,
                navText: "Gossip",
                header: "Gossip",
                navIcon: "mdi-ear-hearing",
            }
        },
    },
    {
        name: "GossipCreate",
        path: "/gossip/create",
        component: GossipCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Gossip > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "GossipEdit",
        path: "/gossip/edit/:slug",
        component: GossipEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Gossip > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "GossipTrash",
        path: "/gossip/trash",
        component: GossipTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Gossip > Trash",
                navIcon: "",
            }
        }
    }
]
