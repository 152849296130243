var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-input"},[_c('div',{staticClass:"v-input__control"},[_c('label',{staticClass:"v-label v-label--active theme--light mb-2",domProps:{"textContent":_vm._s(_vm.label)}}),_c('v-combobox',{attrs:{"items":_vm.items,"search-input":_vm.search,"label":"Type tags","item-value":"id","item-text":"name","hide-selected":"","multiple":"","small-chips":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"lighten-3","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.name.trim())+" ")])]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":"lighten-3","input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-text-field',{staticClass:"d-none",attrs:{"rules":_vm.rules},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }