import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default {
    app_id: env.app_id,
    file_url: env.file_url || 'http://localhost:3001',
    file_path: env.file_path || 'http://localhost:3001/media',
    my_folder: env.my_folder || 'factceleb',
    auth_token: store.getters['login/auth_token'],
    apiToken: env.api_token || '124e8fe6-9870-4033-b3e2-a88936742587'
}