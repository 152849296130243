<template>
  <div class="v-input">
    <div class="v-input__control">
      <label
        class="v-label v-label--active theme--light mt-6 mb-2"
        v-text="label"
      ></label>
      <div class="editor">
        <div v-if="editor" class="editor-bar">
          <template v-if="id == 'description'">
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .wrapInList('heading', { level: 3 })
                  .run()
              "
              :color="editor.isActive('bold') ? 'primary' : ''"
              ><v-icon small>fas fa-home</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleBold()
                  .run()
              "
              :color="editor.isActive('bold') ? 'primary' : ''"
              ><v-icon small>fas fa-bold</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleItalic()
                  .run()
              "
              :color="editor.isActive('italic') ? 'primary' : ''"
              ><v-icon small>fas fa-italic</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleUnderline()
                  .run()
              "
              :color="editor.isActive('underline') ? 'primary' : ''"
              ><v-icon small>fas fa-underline</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleSubscript()
                  .run()
              "
              :color="editor.isActive('subscript') ? 'primary' : ''"
              ><v-icon small>fas fa-subscript</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleSuperscript()
                  .run()
              "
              :color="editor.isActive('superscript') ? 'primary' : ''"
              ><v-icon small>fas fa-superscript</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleBlockquote()
                  .run()
              "
              :color="editor.isActive('blockquote') ? 'primary' : ''"
              ><v-icon small>fas fa-quote-left</v-icon></v-btn
            >
          </template>
          <v-btn
            class="px-2"
            elevation="0"
            small
            @click="
              editor
                .chain()
                .focus()
                .toggleOrderedList()
                .run()
            "
            :color="editor.isActive('orderedList') ? 'primary' : ''"
            ><v-icon small>fas fa-list-ol</v-icon></v-btn
          >
          <template v-if="id == 'description'">
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleBulletList()
                  .run()
              "
              :color="editor.isActive('bulletList') ? 'primary' : ''"
              ><v-icon small>fas fa-list-ul</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .setHeading({ level: 1 })
                  .run()
              "
              :color="editor.isActive('heading', { level: 1 }) ? 'primary' : ''"
            >
              <v-icon small>fas fa-heading</v-icon>
              <span style="font-weight:bold; font-size:20px;">1</span>
            </v-btn>
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .setHeading({ level: 2 })
                  .run()
              "
              :color="editor.isActive('heading', { level: 2 }) ? 'primary' : ''"
            >
              <v-icon small>fas fa-heading</v-icon>
              <span style="font-weight:bold; font-size:20px;">2</span>
            </v-btn>
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .setHeading({ level: 3 })
                  .run()
              "
              :color="editor.isActive('heading', { level: 3 }) ? 'primary' : ''"
            >
              <v-icon small>fas fa-heading</v-icon>
              <span style="font-weight:bold; font-size:20px;">3</span>
            </v-btn>
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .setHeading({ level: 4 })
                  .run()
              "
              :color="editor.isActive('heading', { level: 4 }) ? 'primary' : ''"
            >
              <v-icon small>fas fa-heading</v-icon>
              <span style="font-weight:bold; font-size:20px;">4</span>
            </v-btn>
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .setParagraph()
                  .run()
              "
              :color="editor.isActive('paragraph') ? 'primary' : ''"
              ><v-icon small>fas fa-paragraph</v-icon></v-btn
            >
          </template>
          <v-btn
            class="px-2"
            elevation="0"
            small
            @click="
              editor
                .chain()
                .focus()
                .undo()
                .run()
            "
            :disabled="!editor.can().undo()"
            ><v-icon small>fas fa-undo</v-icon></v-btn
          >
          <v-btn
            class="px-2"
            elevation="0"
            small
            @click="
              editor
                .chain()
                .focus()
                .redo()
                .run()
            "
            :disabled="!editor.can().redo()"
            ><v-icon small>fas fa-redo</v-icon></v-btn
          >
          <v-btn class="px-2" elevation="0" small @click.prevent="openLinkPop"
            ><v-icon small>fas fa-link</v-icon></v-btn
          >
          <v-btn
            class="px-2"
            elevation="0"
            small
            @click.prevent="removeLink"
            :disabled="!editor.isActive('link')"
            ><v-icon small>fas fa-unlink</v-icon></v-btn
          >
          <template v-if="id == 'description'">
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click.prevent="openFileManager"
              ><v-icon>fas fa-image</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click.prevent="openIframePop"
              ><v-icon>fas fa-video</v-icon></v-btn
            >
            <br />
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .insertTable({ rows: 4, cols: 2 })
                  .run()
              "
              ><v-icon>mdi-table</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleHeaderColumn()
                  .run()
              "
              ><v-icon>mdi-table-arrow-left</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleHeaderRow()
                  .run()
              "
              ><v-icon>mdi-table-arrow-up</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .addColumnBefore()
                  .run()
              "
              ><v-icon>mdi-table-column-plus-before</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .addColumnAfter()
                  .run()
              "
              ><v-icon>mdi-table-column-plus-after</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .deleteColumn()
                  .run()
              "
              ><v-icon>mdi-table-column-remove</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .toggleHeaderRow()
                  .run()
              "
              ><v-icon>mdi-table-row</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .addRowBefore()
                  .run()
              "
              ><v-icon>mdi-table-row-plus-before</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .addRowAfter()
                  .run()
              "
              ><v-icon>mdi-table-row-plus-after</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .deleteRow()
                  .run()
              "
              ><v-icon>mdi-table-row-remove</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .mergeCells()
                  .run()
              "
              ><v-icon>mdi-table-merge-cells</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .splitCell()
                  .run()
              "
              ><v-icon>mdi-table-split-cell</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .deleteTable()
                  .run()
              "
              ><v-icon>mdi-table-remove</v-icon></v-btn
            >
            <v-btn
              class="px-2"
              elevation="0"
              small
              @click="
                editor
                  .chain()
                  .focus()
                  .fixTables()
                  .run()
              "
              ><v-icon>mdi-table-check</v-icon></v-btn
            >
          </template>
        </div>
        <div :class="id" class="editor-content" />
      </div>
      <div class="word-count" v-if="editor && editor.getText()">
        {{ editor.getText().match(/\S+/g).length }} words
      </div>
      <link-pop
        :openLink="openLink"
        :linkAttrs="linkAttrs"
        @closeLink="closeLinkPop"
        @addLink="addLink"
      />
      <iframe-pop
        :openIframe="openIframe"
        :iframeAttrs="iframeAttrs"
        @closeIframe="closeIframePop"
        @addIframe="addIframe"
      />
      <manager
        :openManager="openManager"
        :multiple="multiple"
        @closeManger="closeManager"
        @selected="selectedFile"
      />
    </div>
  </div>
</template>

<script>
import { Editor } from "@tiptap/core";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Underline from "@tiptap/extension-underline";
import Blockquote from "@tiptap/extension-blockquote";
import Heading from "@tiptap/extension-heading";
import History from "@tiptap/extension-history";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Youtube from "@tiptap/extension-youtube";
import Link from "@tiptap/extension-link";
import LinkPop from "./link";
import IframePop from "./iframe";
import Figure from "./extensions/extension-figure";
import Manager from "@/components/file-manager/main/manager";

export default {
  props: ["id", "value", "label", "loaded"],
  components: {
    LinkPop,
    IframePop,
    Manager,
  },
  data() {
    return {
      editor: null,
      editorClass: "",
      openLink: false,
      linkAttrs: {},
      openIframe: false,
      iframeAttrs: {},
      openManager: false,
      multiple: false,
    };
  },
  async mounted() {
    this.editorClass = this.id ? this.id : "editor-content";
    if (this.loaded) {
      this.initEditor();
    }
  },
  watch: {
    loaded() {
      if (this.loaded) {
        this.initEditor();
      }
    },
  },
  methods: {
    initEditor() {
      let self = this;
      this.editor = new Editor({
        element: document.querySelector(`.${this.id}`),
        content: this.value,
        extensions:
          this.id === "description"
            ? [
                Document,
                Paragraph,
                Text,
                Bold,
                Italic,
                Subscript,
                Superscript,
                Underline,
                Blockquote,
                Heading,
                History,
                Dropcursor,
                Gapcursor,
                BulletList.configure({
                  itemTypeName: "listItem",
                }),
                OrderedList.configure({
                  itemTypeName: "listItem",
                }),
                ListItem,
                Table.configure({
                  resizable: false,
                }),
                TableRow,
                TableHeader,
                TableCell,
                Link.configure({
                  openOnClick: false,
                  linkOnPaste: true,
                  HTMLAttributes: {
                    target: null,
                    rel: null,
                  },
                }),
                Youtube.configure({
                  inline: false,
                }),
                Figure,
              ]
            : [
                Document,
                Paragraph,
                Text,
                History,
                OrderedList.configure({
                  itemTypeName: "listItem",
                }),
                ListItem,
                Link.configure({
                  openOnClick: false,
                  linkOnPaste: true,
                  HTMLAttributes: {
                    target: null,
                    rel: null,
                  },
                }),
              ],
        onUpdate({ editor }) {
          self.$emit("input", editor.getHTML());
        },
      });
    },
    addLink(form) {
      this.closeLinkPop();
      let attrs = { href: form.url };
      if (form.openInNewTab) attrs.target = "_blank";

      if (form.noFollow) attrs.rel = "nofollow";
      else attrs.rel = "";

      if (form.noOpener) attrs.rel += (attrs.rel ? " " : "") + "noopener";

      if (form.noReferrer) attrs.rel += (attrs.rel ? " " : "") + "noreferrer";

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink(attrs)
        .run();
    },
    openLinkPop() {
      this.openLink = true;
      this.linkAttrs = this.editor.getAttributes("link");
    },
    closeLinkPop() {
      this.openLink = false;
    },
    removeLink() {
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .unsetLink()
        .run();
    },
    openIframePop() {
      this.openIframe = true;
    },
    closeIframePop() {
      this.openIframe = false;
    },
    addIframe(attrs) {
      this.editor.commands.setYoutubeVideo({
        src: attrs.url,
        width: Math.max(320, parseInt(this.width, 10)) || 640,
        height: Math.max(180, parseInt(this.height, 10)) || 480,
      });
      this.closeIframePop();
    },
    openFileManager() {
      this.openManager = true;
    },
    closeManager() {
      this.openManager = false;
    },
    selectedFile(files) {
      if (!Array.isArray(files.images)) {
        this.editor
          .chain()
          .focus()
          .setFigure({ src: files.images, alt: files.caption })
          .run();
      }
      this.openManager = false;
    },
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },
};
</script>
<style scoped>
.editor {
  border: solid thin #999;
  border-radius: 5px;
}
.editor-bar {
  border-bottom: solid thin #999;
}
.editor-bar .v-btn {
  min-width: 30px !important;
  border-radius: 0;
  padding: 0;
}
.editor-bar .v-select {
  min-width: 30px !important;
  border-radius: 0;
  display: inline-block;
}
.editor-bar button:first-child {
  border-radius: 4px 0 0 0;
}
.editor-content {
  margin: 5px;
  max-height: 550px;
  overflow: auto;
}

.word-count {
  text-align: right;
  color: #999;
}
</style>
<style>
/* .editor-content .ProseMirror:first-child{
    display:none;
} */
.editor-content .ProseMirror:focus {
  outline: none !important;
}
.editor-content p {
  margin-bottom: 0 !important;
}
.editor-content blockquote {
  margin-left: 20px;
  padding-left: 10px;
  border-left: solid 3px #ccc;
}
.editor-content ol li {
  list-style-type: decimal;
}
.editor-content ol ol li {
  list-style-type: lower-alpha;
}
.editor-content ol ol ol li {
  list-style-type: lower-roman;
}
.editor-content table {
  border-collapse: collapse;
  width: 100%;
}
.editor-content table th {
  background-color: #eee;
}
.editor-content table th,
.editor-content table td {
  border: solid thin #aaa;
  min-width: 10px;
}
.editor-content table .selectedCell {
  background-color: #c8c8ff66;
}
</style>
<style>
.main-editor h2 {
  font-size: 24px;
  color: #29648a;
}
.main-editor h3 {
  font-size: 21px;
  color: #2e9cca;
}
.main-editor h4 {
  font-size: 18;
  color: #29648a;
}
.main-editor p {
  margin-bottom: 8px;
}
.editor-content figure {
  width: auto;
  height: 400px;
}
.editor-content figure figcaption {
  color: #bbb;
}
.editor-content figure img {
  display: block;
  margin: auto 0 auto 0;
  height: 350px;
  width: 350px;
  max-width: 350px;
}
.editor-content > div > h1 {
  font-size: 30px;
  padding-top: 20px;
  color: #00388b;
}
.editor-content > div > h2 {
  font-size: 27px;
  padding-top: 20px;
  color: #0048b5;
}
.editor-content > div > h3 {
  font-size: 24px;
  padding-top: 20px;
  color: #660000;
}
.editor-content > div > h4 {
  font-size: 21px;
  padding-top: 20px;
  color: #3687ff;
}
.editor-content div a {
  color: #1370f9;
  text-decoration: underline;
}
/* .editor-content div figure{
    margin: 10px 0;
    border: dotted thin #aaa;
    margin-bottom:2px !important;
} */
.editor-content div figure figcaption {
  color: #999;
}
.editor-content div table {
  margin: 10px 0;
}
.editor-content div table th {
  background: rgb(19, 112, 249, 0.6);
  color: #fff;
  border: none;
  padding: 5px;
}
.editor-content div table td {
  border: none;
  padding: 5px;
}
.editor-content div table tr:nth-child(odd) {
  background: rgb(19, 112, 249, 0.1);
}
.editor-content div iframe {
  margin-top: 10px;
}

.editor-content > div > p,
.editor-content > div > h1,
.editor-content > div > h2,
.editor-content > div > h3,
.editor-content > div > h4,
.editor-content > div > table,
.editor-content > div > figure,
.editor-content > div > ul,
.editor-content > div > ol,
.editor-content > div > iframe,
.editor-content > div > blockquote {
  border: dotted thin #999;
  margin-bottom: 2px !important;
}
.editor-content div blockquote {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  padding-left: 10px;
  border-left: solid 5px #ffa726;
  line-height: 35px;
}
</style>
