import Import from '@/views/import';

export default [
    {
        name: 'Import',
        path: '/import',
        component: Import,
        meta: {
            nav: {
                addToNav: false,
                navText: 'Import',
                header: 'Import',
                navIcon: 'mdi-home',
            }
        }
    }
]