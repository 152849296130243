<template>
  <v-form ref="form" v-model="valid" @submit.prevent="save">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
                <span>Edit Personal Detail</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">
                  <div>Id: {{vg_editObj.id}}</div>

                  <person class="mt-2" v-model.trim="form.person" :rules="rules.person" label="Person*"  />

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.nick_name"
                    hint="Please enter nick name"
                    label="Nick name"
                  ></v-text-field>

                  <v-select
                    class="mt-2"
                    :items="sexual_orientation_items"
                    v-model="form.sexual_orientation"
                    hint="Please enter sexual orientation"
                    label="Sexual orientation"
                  ></v-select>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.best_known_for"
                    hint="Please enter best known for"
                    label="Best known for"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.birth_place"
                    hint="Please enter birth place"
                    label="Birth place"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.birth_name"
                    hint="Please enter birth name"
                    label="Birth name"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.ethnicity"
                    hint="Please enter ethnicity"
                    label="Ethnicity"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.nationality"
                    hint="Please enter nationality"
                    label="Nationality"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.high_school"
                    hint="Please enter high school"
                    label="High school"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.university"
                    hint="Please enter university"
                    label="University"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.profession"
                    hint="Please enter profession"
                    label="Profession"
                  ></v-text-field>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.salary"
                    :rules="rules.salary"
                    hint="Please enter salary (per year)"
                    label="Salary"
                    prefix="USD $"
                    :suffix="salary_suffix"
                  ></v-text-field>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.net_worth"
                    :rules="rules.net_worth"
                    hint="Please enter net worth"
                    label="Net worth"
                    prefix="USD $"
                    :suffix="net_worth_suffix"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.sibling"
                    hint="Please enter sibling"
                    label="Siblings"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.children"
                    hint="Please enter children"
                    label="Children"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.ex_spouse"
                    hint="Please enter ex spouse"
                    label="Ex Spouse"
                  ></v-text-field>

                  <h2 class="primary--text">Social Media</h2>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.facebook_url"
                    :rules="rules.facebook_url"
                    hint="Please enter facebook url"
                    label="Facebook url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.twitter_url"
                    :rules="rules.twitter_url"
                    hint="Please enter twitter url"
                    label="Twitter url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.instagram_url"
                    :rules="rules.instagram_url"
                    hint="Please enter instagram url"
                    label="Instagram url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.tiktok_url"
                    :rules="rules.tiktok_url"
                    hint="Please enter tiktok url"
                    label="Tiktok url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.wikipedia_url"
                    :rules="rules.wikipedia_url"
                    hint="Please enter wikipedia url"
                    label="Wikipedia url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.youtube_url"
                    :rules="rules.youtube_url"
                    hint="Please enter youtube url"
                    label="Youtube url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.imdb_url"
                    :rules="rules.imdb_url"
                    hint="Please enter imdb url"
                    label="Imdb url"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.personal_website"
                    :rules="rules.personal_website"
                    hint="Please enter personal website"
                    label="Personal website"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="success" class="mt-5 px-5" right rounded :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash"
import Person from '@/components/Person'
import PersonalDetail from "@/api/person-detail"
import Currency from '@/helper/currency'
import ObjectHelper from '@/helper/object'
import {mapGetters} from "vuex"

export default {
  components: {
    Person
  },
  data() {
    return {
      sexual_orientation_items: [
        {text: 'Straight', value: 'straight'},
        {text: 'Gay', value: 'gay'},
        {text: 'Lesbian', value: 'lesbian'},
        {text: 'Bisexual', value: 'bisexual'},
        {text: 'Pansexual', value: 'pansexual'},
        {text: 'Asexual', value: 'asexual'},
        {text: 'Queer', value: 'queer'},
        {text: 'Lgbtq+', value: 'lgbtq'},
      ],
      valid: null,
      salary_suffix: '',
      net_worth_suffix: '',
      form: {
        person: null,
        nick_name: null,
        best_known_for: null,
        birth_name: null,
        ethnicity: null,
        nationality: null,
        high_school: null,
        university: null,
        profession: null,
        salary: null,
        net_worth: null,
        sibling: null,
        children: null,
        ex_spouse: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        tiktok_url: null,
        wikipedia_url: null,
        youtube_url: null,
        imdb_url: null,
        personal_website: null,
      },
      rules: {
        person: [
          v => !!v || "Person is required",
          v => (typeof v=='object') || 'Select person from drop down'
        ],
        salary: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=100000000 || "Must be less than or equals to 100000000",
        ],
        net_worth: [
          v => !v ||  v>=0 || "Must be greater than or equals to 0",
          v => !v ||  v<=900000000000 || "Must be less than or equals to 900000000000",
        ],
        facebook_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Facebook url is not valid",
        ],
        twitter_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Twitter url is not valid",
        ],
        instagram_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Instagram url is not valid",
        ],
        tiktok_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Toktok url is not valid",
        ],
        wikipedia_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Wikipedia url is not valid",
        ],
        youtube_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Youtube url is not valid",
        ],
        imdb_url: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Imdb url is not valid",
        ],
        personal_website: [
          v => !v || /^(http|https):\/\/[^ "].+$/.test(v) || "Personal website is not valid",
        ],
      }
    };
  },
  mounted(){
    this.form = _.pick(this.vg_editObj, ["person","nick_name","sexual_orientation","best_known_for","birth_place","birth_name","ethnicity","nationality","high_school","university","profession","salary","net_worth","sibling","children","ex_spouse","facebook_url","twitter_url","instagram_url","tiktok_url","wikipedia_url","youtube_url","imdb_url","personal_website"])
  },
  computed: {
      ...mapGetters({
          vg_editObj: "person_detail/editObj"
      })
  },
  watch: {
    'form.salary'(val){
      if(val){
        this.salary_suffix = Currency.humanReadable(val)
      }else{
        this.salary_suffix = ''
      }
    },
    'form.net_worth'(val){
      if(val){
        this.net_worth_suffix = Currency.humanReadable(val)
      }else{
        this.net_worth_suffix = ''
      }
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await PersonalDetail.update(this.vg_editObj.id, ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "PersonalDetail"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "PersonalDetail" });
    }
  }
};
</script>