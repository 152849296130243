import { Node, mergeAttributes } from "@tiptap/core";

export default Node.create({
  name: "figure",

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block",

  content: "block+",

  draggable: true,

  parseHTML() {
    return [
      {
        tag: "figure",
        getAttrs(dom) {
          const img = dom.querySelector("img");
          return {
            src: img ? img.src : null,
            alt: img ? img.alt : null,
          };
        },
      },
      {
        tag: "span>span>img",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "figure",
      ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
      ["figcaption", 0],
    ];
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
    };
  },

  addCommands() {
    return {
      setFigure: (attributes) => ({ commands }) => {
        return commands.wrapIn("figure", attributes);
      },
    };
  },

  addNodeView() {
    return ({ HTMLAttributes }) => {
      const figure = document.createElement("figure");
      const image = document.createElement("img");
      const caption = document.createElement("figcaption");

      image.src = HTMLAttributes.src;
      image.alt = HTMLAttributes.alt;
      caption.contentEditable = true;
      figure.append(image, caption);
      return {
        dom: figure,
        contentDOM: caption,
      };
    };
  },
});
