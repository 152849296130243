import { factcelebApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    list(option = '') {
        return factcelebApi({
            method: 'get',
            url: `/gossip?${option}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    listLinkAndTitle(search=''){
        return factcelebApi({
            method: 'get',
            url: `/gossip/search?word=${search}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    close(id){
        return factcelebApi({
            method: 'get',
            url: `/gossip/${id}/close`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    find(slug, user_id){
        return factcelebApi({
            method: 'get',
            url: `/gossip/${slug}?user_id=${user_id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    create(data) {
        return factcelebApi({
            method: 'post',
            url: `/gossip`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    update(id, data) {
        return factcelebApi({
            method: 'patch',
            url: `/gossip/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    delete(id) {
        return factcelebApi({
            method: 'delete',
            url: `/gossip/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    trash(){
        return factcelebApi({
            method: 'get',
            url: `/gossip/trashes`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    restore(id){
        return factcelebApi({
            method: 'put',
            url: `/gossip/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})
