import Timeline from "@/views/timeline";
import TimelineCreate from "@/views/timeline/create"
import TimelineEdit from "@/views/timeline/edit"
import TimelineTrash from "@/views/timeline/trash"

export default [
    {
        name: "Timeline",
        path: "/timeline",
        component: Timeline,
        meta: {
            nav: {
                addToNav: true,
                navText: "Timeline",
                header: "Timeline",
                navIcon: "mdi-timeline-clock",
            }
        },
    },
    {
        name: "TimelineCreate",
        path: "/timeline/create",
        component: TimelineCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Timeline > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "TimelineEdit",
        path: "/timeline/edit",
        component: TimelineEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Timeline > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "TimelineTrash",
        path: "/timeline/trash",
        component: TimelineTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Timeline > Trash",
                navIcon: "",
            }
        }
    }
]
