<template>
  <v-container fill-height fluid>
    <v-row align="center"
      justify="center">
      <v-col>
        <div class="title text-center">Welcome to Factceleb Admin</div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  data(){
    return {
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      
    })
  },
  methods: {
    ...mapMutations({
    })
  }
}
</script>
