<template>
  <v-dialog v-model="openManager" persistent fullscreen>
    <v-card>
      <v-toolbar elevation="0" dark class="secondary">
        <v-toolbar-title>File Manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeManager">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-card>
              <v-card-title>
                <span class="ml-5">Folder</span>
              </v-card-title>
              <v-card-text v-if="folders.length === 0"
                >No folder found.</v-card-text
              >
              <v-card-text v-else style="max-height:500px; overflow: auto;">
                <v-treeview
                  open-all
                  activatable
                  :items="folders"
                  @update:active="select"
                >
                  <template v-slot:prepend="{ open }">
                    <v-icon>
                      {{ open ? "mdi-folder-open" : "mdi-folder" }}
                    </v-icon>
                  </template>
                </v-treeview>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="9">
            <v-card v-if="selectedFolder">
              <v-card-title>
                <span v-if="selectedFolder">{{ selectedFolder }}</span>
                <span v-else>Contents</span>
                <v-spacer />
                <v-text-field
                  v-model="options.search"
                  clearable
                  flat
                  solo
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                ></v-text-field>
                <v-spacer />
                <v-btn color="primary" @click.prevent="uploadPop">
                  <v-icon>mdi-upload</v-icon>
                  <span>Upload</span>
                </v-btn>
                <v-btn
                  :disabled="selectedFiles.length === 0"
                  class="ml-5"
                  color="primary"
                  @click.prevent="confirmSelection"
                >
                  <v-icon>mdi-check</v-icon>
                  <span>Confirm</span>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <loading v-if="loading" />
                <div v-else-if="files.length === 0">No file found.</div>
                <div v-else>
                  <v-data-iterator
                    :loading="loading"
                    :items="files.rows"
                    :server-items-length="files.count"
                    :options.sync="options"
                    :items-per-page="options.itemsPerPage"
                  >
                    <template v-slot:default="{ items }">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="3"
                          v-for="(item, index) in items"
                          :key="index"
                        >
                          <item
                            :item="item"
                            :imgs="imgs"
                            :multiple="multiple"
                            :openManager="openManager"
                            :selectedFiles="selectedFiles"
                            @change="updateSelection"
                            @editItem="editItem"
                            @deleteItem="deleteItem"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </div>
              </v-card-text>
            </v-card>
            <v-card v-else>
              <v-card-text>Select folder to show files</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <edit
      :openEdit="openEdit"
      :editObj="editObj"
      @closeEdit="closeEdit"
      @change="updatedFile"
    />
    <upload
      :openUpload="openUpload"
      :selected="selectedFolder"
      :cropWidth="cropWidth"
      :cropHeight="cropHeight"
      @closeUpload="closeUpload"
      @change="uploaded"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import config from "../config.js";
import FileAPI from "../api";
import Loading from "./loading";
import Item from "./item";
import Edit from "./edit";
import Upload from "./upload";
import { stringify } from "query-string";
export default {
  props: ["openManager", "multiple", "cropWidth", "cropHeight"],
  components: {
    Loading,
    Item,
    Edit,
    Upload,
  },
  data() {
    return {
      loading: false,
      folders: [],
      selectedFolder: "",
      options: {
        folder: "",
        search: "",
        itemsPerPage: 15,
        page: 1,
      },
      files: [],
      selectedFiles: [],
      caption: "",
      imgs: ["jpg", "jpeg", "png", "gif", "webp", "avif"],
      openEdit: false,
      editObj: {},
      openUpload: false,
    };
  },
  watch: {
    "options.itemsPerPage"() {
      this.loadFile();
    },
    "options.folder"() {
      this.loadFile();
    },
    "options.page"() {
      this.loadFile();
    },
    "options.search": {
      handler: _.debounce(async function() {
        this.options.page = 1;
        this.loadFile();
      }, 900),
    },
    openManager(val) {
      if (val) {
        this.loadFolder();
      }
    },
  },
  mounted() {
    if (this.openManager) {
      this.loadFolder();
    }
  },
  methods: {
    optionsToQueryString(tableOptionObject) {
      if (!tableOptionObject) return;

      let queryStringObject = {
        folder: tableOptionObject.folder,
        page: tableOptionObject.page,
        items: tableOptionObject.itemsPerPage,
      };

      if (tableOptionObject.search) {
        queryStringObject.search = tableOptionObject.search;
      }

      return stringify(queryStringObject);
    },
    async loadFolder() {
      try {
        let { data } = await FileAPI.tree(config.my_folder);
        if (data.payload) {
          this.folders = data.payload;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async loadFile() {
      try {
        this.loading = true;
        let { data } = await FileAPI.list(
          this.optionsToQueryString(this.options)
        );
        this.files = data.payload;
        this.loading = false;
      } catch (err) {
        this.files = [];
        this.loading = false;
        console.log(err);
      }
    },
    select(item) {
      this.selectedFolder = config.my_folder + "/" + item[0];
      this.options.folder = config.my_folder + "/" + item[0];
      this.options.page = 1;
    },
    updateSelection(obj) {
      if (this.multiple) {
        if (obj.status) {
          // added
          this.selectedFiles.push(obj.file);
        } else {
          // removed
          this.selectedFiles.splice(this.selectedFiles.indexOf(obj.file), 1);
        }
      } else {
        this.caption = obj.caption;
        this.selectedFiles = [obj.file];
      }
    },
    confirmSelection() {
      this.$emit("selected", {
        images: this.multiple ? this.selectedFiles : this.selectedFiles[0],
        caption: this.caption,
      });
      this.selectedFiles = [];
    },
    uploadPop() {
      this.openUpload = true;
    },
    closeUpload() {
      this.openUpload = false;
    },
    async uploaded() {
      try {
        this.closeUpload();
        let { data } = await FileAPI.list(
          this.optionsToQueryString(this.options)
        );
        this.files = data.payload;
      } catch (err) {
        this.files = [];
      }
    },
    closeEdit() {
      this.openEdit = false;
    },
    editItem(item) {
      this.editObj = item;
      this.openEdit = true;
    },
    async updatedFile() {
      this.closeEdit();
      try {
        let { data } = await FileAPI.list(
          this.optionsToQueryString(this.options)
        );
        this.files = data.payload;
      } catch (err) {
        this.files = [];
      }
    },
    async deleteItem() {
      try {
        let { data } = await FileAPI.list(
          this.optionsToQueryString(this.options)
        );
        this.files = data.payload;
      } catch (err) {
        this.files = [];
      }
    },
    closeManager() {
      this.$emit("closeManger");
    },
  },
};
</script>
