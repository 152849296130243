import Relation from "@/views/relation";
import RelationCreate from "@/views/relation/create"
import RelationEdit from "@/views/relation/edit"
import RelationTrash from "@/views/relation/trash"

export default [
    {
        name: "Relation",
        path: "/relation",
        component: Relation,
        meta: {
            nav: {
                addToNav: true,
                navText: "Relation",
                header: "Relation",
                navIcon: "mdi-account-switch",
            }
        },
    },
    {
        name: "RelationCreate",
        path: "/relation/create",
        component: RelationCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Relation > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "RelationEdit",
        path: "/relation/edit",
        component: RelationEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Relation > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "RelationTrash",
        path: "/relation/trash",
        component: RelationTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Relation > Trash",
                navIcon: "",
            }
        }
    }
]
