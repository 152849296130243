import { factcelebApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    list(type = '') {
        return factcelebApi({
            method: 'get',
            url: `/log/${type}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})