<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit Category</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                  ></v-text-field>

                  <v-select
                    class="mt-2"
                    :items="types"
                    v-model="form.type"
                    :rules="rules.type"
                    label="Type*"
                  ></v-select>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.sort"
                    hint="Please enter sort"
                    label="Sort"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash"
import Category from "@/api/category"
import ObjectHelper from '@/helper/object'
import {mapGetters} from "vuex"

export default {
  data() {
    return {
      valid: null,
      form: {
        name: null,
        slug: null,
        type: null,
        sort: null,
      },
      rules: {
        name: [
          v => !!v || "Name is required",
        ],
        slug: [
          v => !!v || "Slug is required",
        ],
        type: [
          v => !!v || "Type is required",
        ]
      },
      types: ['biography', 'gossip', 'movie']
    };
  },
  mounted(){
    this.form = _.pick(this.vg_editObj, ["name","slug","type","sort"])
  },
  computed: {
      ...mapGetters({
          vg_editObj: "category/editObj"
      })
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Category.update(this.vg_editObj.id, ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Category"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Category" });
    }
  }
};
</script>