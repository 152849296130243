export default {
    humanReadable(amount){
        if(!amount) return
        
        if(amount<=999){
            return amount
        }else if(amount>999 && amount<=999999){
            return ((Math.abs(amount)/1000).toFixed(2)) + 'k'
        }else if(amount>999999 && amount<=999999999){
            return ((Math.abs(amount)/1000000).toFixed(2)) + 'M'
        }else if(amount>999999999 && amount<=999999999999){
            return ((Math.abs(amount)/1000000000).toFixed(2)) + 'B'
        }else{
            return "exceed limit"
        }
    }
}