import Biography from "@/views/biography";
import BiographyCreate from "@/views/biography/create"
import BiographyEdit from "@/views/biography/edit"
import BiographyTrash from "@/views/biography/trash"

export default [
    {
        name: "Biography",
        path: "/biography",
        component: Biography,
        meta: {
            nav: {
                addToNav: true,
                navText: "Biography",
                header: "Biography",
                navIcon: "mdi-post",
            }
        },
    },
    {
        name: "BiographyCreate",
        path: "/biography/create",
        component: BiographyCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Biography > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "BiographyEdit",
        path: "/biography/edit/:slug",
        component: BiographyEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Biography > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "BiographyTrash",
        path: "/biography/trash",
        component: BiographyTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Biography > Trash",
                navIcon: "",
            }
        }
    }
]
