<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
                <span>Edit Timeline</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="8" offset-md="2">
                  <div>Id: {{vg_editObj.id}}</div>

                  <person class="mt-2" v-model.trim="form.person" :rules="rules.person" label="Person*"  />

                  <person class="mt-2" v-model.trim="form.linked_person" :rules="rules.linked_person" label="Linked Person"  />

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.date"
                    hint="Please enter date"
                    :label="`Date ${formatData('date')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.doe"
                    hint="Please enter doe"
                    label="Date (text)"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.icon"
                    hint="Please enter icon"
                    label="Icon"
                  ></v-text-field>

                  <v-textarea
                    class="mt-2"
                    v-model="form.event"
                    :rules="rules.event"
                    label="Event*"
                    rows="3"
                  ></v-textarea>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash"
import Person from '@/components/Person'
import Timeline from "@/api/timeline"
import ObjectHelper from '@/helper/object'
import {mapGetters} from "vuex"
import moment from 'moment'

export default {
  components: {
    Person
  },
  data() {
    return {
      
      valid: null,
      form: {
        person: null,
        linked_person: null,
        date: null,
        doe: null,
        icon: null,
        event: null,
      },
      rules: {
        person: [
          v => !!v || "Person is required",
          v => (typeof v=='object') || 'Select person from drop down'
        ],
        linked_person: [
          v => !v || (typeof v=='object') || 'Select person from drop down'
        ],
        event: [
          v => !!v || "Event is required",
        ],
      }
    };
  },
  mounted(){
    this.form = _.pick(this.vg_editObj, ["person","linked_person","date","doe","icon","event"])
  },
  computed: {
      ...mapGetters({
          vg_editObj: "timeline/editObj"
      })
  },
  methods: {
    formatData(type){
      if(!this.form[type]) return ''

      return '('+moment(this.form[type]).format('D MMM YYYY')+')'
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Timeline.update(this.vg_editObj.id, ObjectHelper.emptyStringToNull(this.form))
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
          this.$router.push({name: "Timeline"})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Timeline" });
    }
  }
};
</script>