import Category from "@/views/category";
import CategoryCreate from "@/views/category/create"
import CategoryEdit from "@/views/category/edit"
import CategoryTrash from "@/views/category/trash"

export default [
    {
        name: "Category",
        path: "/category",
        component: Category,
        meta: {
            nav: {
                addToNav: true,
                navText: "Category",
                header: "Category",
                navIcon: "mdi-widgets",
            }
        },
    },
    {
        name: "CategoryCreate",
        path: "/category/create",
        component: CategoryCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Category > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "CategoryEdit",
        path: "/category/edit",
        component: CategoryEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Category > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "CategoryTrash",
        path: "/category/trash",
        component: CategoryTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Category > Trash",
                navIcon: "",
            }
        }
    }
]
