export default {
    emptyStringToNull(objectData){
        if(!objectData || Object.keys(objectData).length==0) return

        for(const ind in objectData){
            if(objectData[ind]===""){
                objectData[ind] = null
            }
        }

        return objectData
    }

}