import { factcelebApi } from '@/api/index.js'
import store from '@/store'

export default ({
    category(data){
        return factcelebApi({
            method: 'post',
            url: `/upload/category`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    biography(data){
        return factcelebApi({
            method: 'post',
            url: `/upload/biography`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    gossip(data){
        return factcelebApi({
            method: 'post',
            url: `/upload/gossip`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})