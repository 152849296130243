<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-card shaped raised class="mt-2">
            <v-card
              class="primary white--text header-bar-content"
              style="margin-top:10px;"
            >
              <v-card-title class="mt-5 px-6">
                <v-btn @click="back" icon class=" mr-2"
                  ><v-icon class="cursor white--text"
                    >mdi-arrow-left</v-icon
                  ></v-btn
                >
                <span>Edit Gossip</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10" offset-md="1">
                  <v-row>
                    <v-col cols="12" md="6">
                      <group-checkbox
                        class="mt-5"
                        :items="categories"
                        v-model="form.category_ids"
                        :rules="rules.category_ids"
                        label="Category*"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <div>Id: {{ gossipObj.id }}</div>

                      <persons
                        class="mt-2"
                        v-model="form.person"
                        :rules="
                          form.status && form.status == 'sponsored'
                            ? []
                            : rules.person
                        "
                        :label="
                          form.status && form.status == 'sponsored'
                            ? 'Persons'
                            : 'Persons*'
                        "
                      />

                      <v-select
                        class="mt-2"
                        :items="status_items"
                        v-model="form.status"
                        :rules="rules.status"
                        label="Status*"
                      ></v-select>

                      <v-text-field
                        type="date"
                        class="mt-2"
                        v-model="form.publish_expire_at"
                        hint="Please enter publish expire date"
                        :label="
                          `Publish Expire at ${formatData('publish_expire_at')}`
                        "
                        hide-details
                      ></v-text-field>

                      <user
                        class="mt-2"
                        v-model="form.assign_to"
                        label="Assign to"
                        hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="6">
                          <v-checkbox
                            v-model="form.featured"
                            label="Featured"
                            hide-details
                          />
                        </v-col>
                        <v-col>
                          <v-checkbox
                            v-model="form.pin"
                            label="Pin"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        type="date"
                        class="mt-2"
                        v-model="form.pin_expire_at"
                        hint="Please enter pin expire date"
                        :label="`Pin Expire at ${formatData('pin_expire_at')}`"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.title"
                    :rules="rules.title"
                    hint="Please enter title"
                    label="Title*"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                  ></v-text-field>

                  <v-row>
                    <v-col cols="12" md="8">
                      <v-textarea
                        class="mt-5"
                        outlined
                        name="textarea"
                        v-model="form.short_description"
                        label="Short description"
                      ></v-textarea>
                      <v-progress-linear
                        rounded
                        :background-color="
                          getProgress('short', 'color') + ' lighten-3'
                        "
                        :color="getProgress('short', 'color')"
                        :value="getProgress('short')"
                        style="position:relative; top:-30px; margin-left:5px; margin-right:5px; width:98%"
                      ></v-progress-linear>
                    </v-col>
                    <v-col cols="12" md="4">
                      <file-manager
                        v-model="form.image"
                        width="200"
                        height="200"
                        cropWidth="500"
                        cropHeight="260"
                        :allowed="['png', 'jpg', 'jpeg']"
                        :multiple="false"
                        label="Image"
                      />
                    </v-col>
                  </v-row>

                  <tag v-model="form.tags" label="Tag" />

                  <editor
                    id="description"
                    :loaded="editorLoaded"
                    v-model="form.description"
                    label="Description"
                  />

                  <editor
                    id="reference"
                    :loaded="editorLoaded"
                    v-model="form.reference"
                    label="Reference"
                  />

                  <v-textarea
                    class="mt-3"
                    outlined
                    name="textarea"
                    v-model="form.meta_content"
                    label="Meta content"
                  ></v-textarea>
                  <v-progress-linear
                    rounded
                    :background-color="
                      getProgress('meta', 'color') + ' lighten-3'
                    "
                    :color="getProgress('meta', 'color')"
                    :value="getProgress('meta')"
                    style="position:relative; top:-30px; margin-left:5px; margin-right:5px; width:98%"
                  ></v-progress-linear>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="cancel"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="save(false)"
                      :disabled="!valid"
                      >Save</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="save(true)"
                      :disabled="!valid"
                      >Finish</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Tag from "@/components/Tag";
import Editor from "@/components/editor";
import Persons from "@/components/Persons";
import User from "@/components/User";
import GossipApi from "@/api/gossip";
import CategoryAPI from "@/api/category";
import UserApi from "@/api/user";
import GroupCheckbox from "@/components/GroupCheckbox";
import fileManager from "@/components/file-manager";
import ObjectHelper from "@/helper/object";
import { mapGetters } from "vuex";

export default {
  components: {
    Tag,
    User,
    Persons,
    Editor,
    GroupCheckbox,
    fileManager,
  },
  data() {
    return {
      status_items: [
        { text: "Assigned", value: "assigned" },
        { text: "Progress", value: "progress" },
        { text: "Completed", value: "completed" },
        { text: "Published", value: "published" },
        { text: "Sponsored", value: "sponsored" },
      ],
      valid: null,
      categories: [],
      editorLoaded: false,
      gossipObj: {},
      form: {
        category_ids: [],
        person: null,
        status: null,
        publish_expire_at: null,
        assigned_to: null,
        featured: false,
        pin: false,
        pin_expire_at: null,
        title: null,
        slug: null,
        short_description: null,
        tags: [],
        image: null,
        description: null,
        reference: null,
        meta_content: null,
      },
      rules: {
        category_ids: [(v) => !!v || "Category is required"],
        person: [
          (v) => !!v || "Person is required",
          (v) => (v && v.length > 0) || "Add Person",
        ],
        status: [(v) => !!v || "Status is required"],
        title: [(v) => !!v || "Title is required"],
        slug: [(v) => !!v || "Slug is required"],
      },
    };
  },
  async mounted() {
    this.editorLoaded = false;
    this.spinner(true);
    let { data } = await CategoryAPI.list("type=gossip");
    this.categories = data.payload.rows.map((each) => {
      return { id: each.id, name: each.name };
    });

    let gossipDataObj = await GossipApi.find(
      this.$route.params.slug,
      this.vg_user.id
    );
    if (gossipDataObj.data.payload) {
      this.gossipObj = gossipDataObj.data.payload;
      this.form = _.pick(this.gossipObj, [
        "person",
        "status",
        "assigned_to",
        "title",
        "slug",
        "featured",
        "pin",
        "pin_expire_at",
        "short_description",
        "image",
        "description",
        "reference",
        "meta_content",
        "published_by",
        "published_at",
      ]);
      if (this.gossipObj.assign_to) {
        let userData = await UserApi.user(this.gossipObj.assign_to);
        this.form.assign_to = userData.data.payload;
      }
      this.form.publish_expire_at = this.gossipObj.publish_expire_at
        ? moment(this.gossipObj.publish_expire_at).format("YYYY-MM-DD")
        : null;
      this.form.category_ids = this.gossipObj.category.map((each) => each.id);
      this.form.tags = this.gossipObj.tag;
      this.editorLoaded = true;
    } else {
      this.snackbar({
        status: true,
        message: "Gossip not found",
        color: "error",
      });
      this.$router.push({ name: "Gossip" });
    }
    this.spinner(false);
  },
  computed: {
    ...mapGetters({
      vg_user: "login/user",
    }),
  },
  methods: {
    formatData(type) {
      if (!this.form[type]) return "";

      return "(" + moment(this.form[type]).format("D MMM YYYY") + ")";
    },
    getProgress(field, type = "") {
      // get field
      let minAllowed, maxAllowed, value;
      if (field == "short") {
        minAllowed = 180;
        maxAllowed = 240;
        value = this.form.short_description
          ? this.form.short_description.length
          : "";
      } else {
        minAllowed = 155;
        maxAllowed = 250;
        value = this.form.meta_content ? this.form.meta_content.length : "";
      }

      // get color or value
      if (type == "color") {
        if (value <= minAllowed) {
          return "grey";
        } else if (value <= maxAllowed) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return parseInt((value / maxAllowed) * 100);
      }
    },
    async save(route) {
      if (this.$refs.form.validate()) {
        try {
          this.spinner(true);
          // if already not published and current is published or sponsored
          if (
            !this.gossipObj.published_at &&
            (this.form.status == "published" || this.form.status == "sponsored")
          ) {
            this.form.published_by = this.vg_user.id;
            this.form.published_at = moment();
          }
          let { data } = await GossipApi.update(
            this.gossipObj.id,
            ObjectHelper.emptyStringToNull(this.form)
          );
          this.snackbar({
            status: true,
            message: data.message,
            color: "success",
          });
          this.spinner(false);
          if (route) {
            await GossipApi.close(this.gossipObj.id);
            this.$router.push({ name: "Gossip" });
          }
        } catch (err) {
          this.snackbar({
            status: true,
            message: err.response.data.message,
            color: "error",
          });
          this.spinner(false);
        }
      }
    },
    async back() {
      // close updating value
      try {
        await GossipApi.close(this.gossipObj.id);
      } catch (err) {
        console.log(err);
      }
      this.$router.go(-1);
    },
    async cancel() {
      // close updating value
      try {
        await GossipApi.close(this.gossipObj.id);
      } catch (err) {
        console.log(err);
      }
      this.$router.push({ name: "Gossip" });
    },
  },
};
</script>
