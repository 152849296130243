<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card
              class="primary white--text header-bar-content"
              style="margin-top:10px;"
            >
              <v-card-title class="mt-5 px-6">
                <v-btn @click="$router.go(-1)" icon class=" mr-2"
                  ><v-icon class="cursor white--text"
                    >mdi-arrow-left</v-icon
                  ></v-btn
                >
                <span>Create Movie</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <ul>
                <li class="error--text" v-for="err in error" :key="err">
                  {{ err }}
                </li>
              </ul>
              <v-row>
                <v-col cols="8" md="5" offset-md="2" class="mb-0 pb-0">
                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="search"
                    hint="Please enter movie name"
                    label="Movie"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="year"
                    hint="Please enter movie year"
                    label="Year"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" offset-md="2" class="mt-0 pt-0">
                  <v-btn
                    color="success"
                    class="px-5"
                    right
                    rounded
                    @click="searchMovie"
                    :disabled="!search"
                    >Search</v-btn
                  >
                </v-col>
                <v-col cols="12" md="10" offset-md="2" v-if="searchData.Title">
                  <a class="mr-4" :href="searchData.Poster" target="_blank"
                    >Image</a
                  >
                  Genre:
                  <v-btn
                    class="d-inline-flex ma-2"
                    x-small
                    v-for="(genre, ind) in getApiData('Genre')"
                    :key="ind"
                    @click="copyIt"
                    >{{ genre }}</v-btn
                  >
                  Actors:
                  <v-btn
                    class="d-inline-flex ma-2"
                    x-small
                    v-for="(actor, ind) in getApiData('Actors')"
                    :key="'a' + ind"
                    @click="copyIt"
                    >{{ actor }}</v-btn
                  >
                  Director:
                  <v-btn
                    class="d-inline-flex ma-2"
                    x-small
                    v-for="(director, ind) in getApiData('Director')"
                    :key="'d' + ind"
                    @click="copyIt"
                    >{{ director }}</v-btn
                  >
                  Writer:
                  <v-btn
                    class="d-inline-flex ma-2"
                    x-small
                    v-for="(writer, ind) in getApiData('Writer')"
                    :key="'w' + ind"
                    @click="copyIt"
                    >{{ writer }}</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                  offset-md="2"
                  v-if="searchData.Error"
                  class="error--text"
                  >{{ searchData.Error }}</v-col
                >
              </v-row>

              <v-row>
                <v-col cols="12" md="8" offset-md="2">
                  <genre
                    class="mt-2"
                    v-model="form.genre"
                    :rules="rules.genre"
                    label="Genre*"
                  />

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="form.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model.trim="form.slug"
                    :rules="rules.slug"
                    hint="Please enter slug"
                    label="Slug*"
                  ></v-text-field>

                  <file-manager
                    v-model="form.image"
                    width="200"
                    height="200"
                    :allowed="['png', 'jpg', 'jpeg']"
                    :multiple="false"
                    label="Image"
                  />

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.year"
                    hint="Please enter year"
                    label="Year"
                  ></v-text-field>

                  <v-text-field
                    type="date"
                    class="mt-2"
                    v-model="form.release_date"
                    hint="Please enter release date"
                    :label="`Release date ${formatData('release_date')}`"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.language"
                    hint="Please enter language"
                    label="Language"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.country_of_origin"
                    hint="Please enter country of origin"
                    label="Country of origin"
                  ></v-text-field>

                  <v-text-field
                    type="url"
                    class="mt-2"
                    v-model="form.trailer"
                    :rules="rules.trailer"
                    hint="Please enter trailer"
                    label="Trailer"
                  ></v-text-field>

                  <v-textarea
                    name="textarea"
                    v-model="form.synopsis"
                    :rules="rules.synopsis"
                    label="Synopsis"
                  ></v-textarea>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.rating"
                    :rules="rules.rating"
                    hint="Please enter rating"
                    label="Rating"
                    suffix="in 10"
                  ></v-text-field>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.imdb"
                    :rules="rules.imdb"
                    hint="Please enter imdb"
                    label="Imdb"
                    suffix="in 10"
                  ></v-text-field>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.google"
                    :rules="rules.google"
                    hint="Please enter google"
                    label="Google"
                    suffix="%"
                  ></v-text-field>

                  <v-text-field
                    type="number"
                    class="mt-2"
                    v-model.number="form.rotten_tomato"
                    :rules="rules.rotten_tomato"
                    hint="Please enter rotten tomato"
                    label="Rotten tomato"
                    suffix="%"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.box_office"
                    hint="Please enter box office"
                    label="Box office"
                    prefix="USD $"
                    :suffix="box_office_suffix"
                    append-icon="mdi-timer-10"
                    @click:append="multiply('box_office')"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.budget"
                    hint="Please enter budget"
                    label="Budget"
                    prefix="USD $"
                    :suffix="budget_suffix"
                    append-icon="mdi-timer-10"
                    @click:append="multiply('budget')"
                  ></v-text-field>

                  <v-text-field
                    type="text"
                    class="mt-2"
                    v-model="form.running_time"
                    hint="Please enter running time"
                    label="Running time"
                  ></v-text-field>

                  <v-textarea
                    type="text"
                    class="mt-2"
                    v-model="form.production_company"
                    hint="Please enter production company"
                    label="Production company"
                    rows="3"
                  ></v-textarea>

                  <v-select
                    class="mt-2"
                    :items="mpa_items"
                    v-model="form.mpa"
                    label="Mpa"
                  ></v-select>

                  <v-select
                    class="mt-2"
                    :items="sex_scene_items"
                    v-model="form.sex_scene"
                    label="Sex scene"
                  ></v-select>

                  <v-select
                    class="mt-2"
                    :items="nudity_scene_items"
                    v-model="form.nudity_scene"
                    label="Nudity scene"
                  ></v-select>

                  <v-select
                    class="mt-2"
                    :items="drugs_items"
                    v-model="form.drugs"
                    label="Drugs"
                  ></v-select>

                  <v-select
                    class="mt-2"
                    :items="violence_items"
                    v-model="form.violence"
                    label="Violence"
                  ></v-select>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="cancel"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="save(false)"
                      :disabled="!valid"
                      >Save</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      class="mt-5 px-5"
                      right
                      rounded
                      @click="save(true)"
                      :disabled="!valid"
                      >Finish</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Movie from "@/api/movie";
import Genre from "@/components/Genre";
import fileManager from "@/components/file-manager";
import ObjectHelper from "@/helper/object";
import Currency from "@/helper/currency";
import moment from "moment";

export default {
  components: {
    fileManager,
    Genre,
  },
  data() {
    return {
      error: [],
      box_office_suffix: "",
      budget_suffix: "",
      mpa_items: [
        { text: "None", value: null },
        { text: "G", value: "G" },
        { text: "PG", value: "PG" },
        { text: "PG-13", value: "PG-13" },
        { text: "R", value: "R" },
        { text: "NC-17", value: "NC-17" },
      ],
      sex_scene_items: ["none", "mild", "severe"],
      nudity_scene_items: ["none", "mild", "severe"],
      drugs_items: ["none", "mild", "severe"],
      violence_items: ["none", "mild", "severe"],
      valid: null,
      form: {
        genre: null,
        name: null,
        slug: null,
        image: null,
        year: null,
        release_date: null,
        language: null,
        country_of_origin: null,
        trailer: null,
        synopsis: null,
        rating: null,
        imdb: null,
        google: null,
        rotten_tomato: null,
        box_office: null,
        budget: null,
        running_time: null,
        production_company: null,
        mpa: null,
        sex_scene: null,
        nudity_scene: null,
        drugs: null,
        violence: null,
      },
      search: "",
      year: "",
      searchData: {},
      searchInfo: "",
      rules: {
        genre: [
          (v) => !!v || "Genre is required",
          (v) => (!!v && v.length > 0) || "Add Genre",
        ],
        name: [(v) => !!v || "Name is required"],
        slug: [(v) => !!v || "Slug is required"],
        trailer: [
          (v) =>
            !v ||
            /^(http|https):\/\/[^ "].+$/.test(v) ||
            "Trailer is not valid",
        ],
        synopsis: [],
        rating: [
          (v) => !v || v >= 0 || "Must be greater than or equals to 0",
          (v) => !v || v <= 10 || "Must be less than or equals to 10",
        ],
        imdb: [
          (v) => !v || v >= 0 || "Must be greater than or equals to 0",
          (v) => !v || v <= 10 || "Must be less than or equals to 10",
        ],
        google: [
          (v) => !v || v >= 0 || "Must be greater than or equals to 0",
          (v) => !v || v <= 100 || "Must be less than or equals to 100",
        ],
        rotten_tomato: [
          (v) => !v || v >= 0 || "Must be greater than or equals to 0",
          (v) => !v || v <= 100 || "Must be less than or equals to 100",
        ],
      },
    };
  },
  watch: {
    "form.name"(value) {
      this.form.slug = value
        .trim()
        .toLowerCase()
        .replace(/[."“”‘’,()&_%~@#:<>{}+-`+=[\]]/g, "")
        .replaceAll(" ", "-")
        .replace(/-{2,}/g, "-");
    },
    "form.box_office"(val) {
      let value = val;
      if (value) {
        this.box_office_suffix = Currency.humanReadable(value);
      } else {
        this.box_office_suffix = "";
      }
    },
    "form.budget"(val) {
      if (val) {
        this.budget_suffix = Currency.humanReadable(val);
      } else {
        this.budget_suffix = "";
      }
    },
  },
  methods: {
    multiply(field) {
      this.form[field] = (parseFloat(this.form[field]) * 1000000).toString();
    },
    formatData(type) {
      if (!this.form[type]) return "";

      return "(" + moment(this.form[type]).format("D MMM YYYY") + ")";
    },
    async searchMovie() {
      let res = await Movie.search(this.search, this.year);
      this.searchData = res.data;
      if (res.status == 200 && this.searchData.Title) {
        this.form.name = this.searchData.Title;
        this.form.slug = this.searchData.Title.trim()
          .replace(/[^a-zA-Z ]/g, "")
          .replaceAll(" ", "-")
          .replace(/-{2,}/g, "-")
          .toLowerCase();
        this.form.year = this.searchData.Year;
        this.form.release_date = moment(
          this.searchData.Released,
          "DD MMM YYYY"
        ).format("YYYY-MM-DD");
        this.form.language = this.searchData.Language;
        this.form.country_of_origin = this.searchData.Country;
        this.form.synopsis = this.searchData.Plot;
        this.form.imdb =
          this.searchData.imdbRating !== "N/A"
            ? this.searchData.imdbRating
            : "";
        this.form.google =
          this.searchData.Ratings[0].Value === "N/A"
            ? parseInt(this.searchData.Ratings[0].Value) * 10
            : "";
        this.form.rotten_tomato =
          this.searchData.Ratings[1].Source === "Rotten Tomatoes"
            ? this.searchData.Ratings[1].Value !== "N/A"
              ? this.searchData.Ratings[1].Value.replace("%", "")
              : ""
            : this.form.rotten_tomato;
        this.form.box_office =
          this.searchData.BoxOffice !== "N/A"
            ? this.searchData.BoxOffice.replace("$", "").replaceAll(",", "")
            : "";
        this.form.running_time = this.searchData.Runtime;
        this.form.production_company = this.searchData.production_company;
        this.form.mpa = this.searchData.Rated;
      } else {
        this.searchInfo = res.Error;
      }
    },
    getApiData(key) {
      if (key && this.searchData && this.searchData[key]) {
        return this.searchData[key].split(",");
      } else {
        return [];
      }
    },
    async copyIt($event) {
      if ($event.target.textContent) {
        await navigator.clipboard.writeText($event.target.textContent.trim());
      }
    },
    async save(route = false) {
      if (this.$refs.form.validate()) {
        try {
          this.spinner(true);
          let { data } = await Movie.create(
            ObjectHelper.emptyStringToNull(this.form)
          );
          this.snackbar({
            status: true,
            message: data.message,
            color: "success",
          });
          this.spinner(false);
          if (route) this.$router.push({ name: "Movie" });
        } catch (err) {
          this.error = err.response.data.payload;
          this.snackbar({
            status: true,
            message: err.response.data.message,
            color: "error",
          });
          this.spinner(false);
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Movie" });
    },
  },
};
</script>
