import Genre from "@/views/genre";
import GenreCreate from "@/views/genre/create"
import GenreEdit from "@/views/genre/edit"
import GenreTrash from "@/views/genre/trash"

export default [
    {
        name: "Genre",
        path: "/genre",
        component: Genre,
        meta: {
            nav: {
                addToNav: true,
                navText: "Genre",
                header: "Genre",
                navIcon: "mdi-tag-faces",
            }
        },
    },
    {
        name: "GenreCreate",
        path: "/genre/create",
        component: GenreCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Genre > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "GenreEdit",
        path: "/genre/edit",
        component: GenreEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Genre > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "GenreTrash",
        path: "/genre/trash",
        component: GenreTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Genre > Trash",
                navIcon: "",
            }
        }
    }
]
