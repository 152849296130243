var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"shaped":"","elevation":"6"}},[_c('v-card',{staticClass:"primary white--text header-bar-content",staticStyle:{"margin-top":"10px"}},[_c('v-card-title',{staticClass:"px-6"},[_c('span',[_vm._v("Timeline")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-9",attrs:{"dark":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),(_vm.role.add)?_c('v-btn',{staticClass:"mt-4",attrs:{"color":"white black--text","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.createApi.apply(null, arguments)}}},[_vm._v("Add")]):_vm._e(),(_vm.role.trashes)?_c('v-btn',{staticClass:"ml-4 mt-4",attrs:{"color":"red darken-4 grey--text text--lighten-2 font-weight-bold","rounded":""},on:{"click":_vm.trashedApi}},[_vm._v("Trash")]):_vm._e()],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-4 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vg_timeline.rows,"loading":_vm.loading,"server-items-length":_vm.vg_timeline.count,"options":_vm.options,"search":_vm.options.search,"items-per-page":_vm.options.itemsPerPage,"footer-props":{'items-per-page-options':[20, 30, 50, 100, -1]}},on:{"update:options":function($event){_vm.options=$event},"update:search":function($event){return _vm.$set(_vm.options, "search", $event)}},scopedSlots:_vm._u([{key:"item.person",fn:function(ref){
var item = ref.item;
return [(item.person)?_c('span',[_vm._v(_vm._s(item.person.name))]):_c('span',{staticClass:"error--text"},[_vm._v("Person id "+_vm._s(item.person_id)+" trashed")])]}},{key:"item.linked_person",fn:function(ref){
var item = ref.item;
return [(item.linked_person)?_c('span',[_vm._v(_vm._s(item.linked_person.name))]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('div',[_vm._v(_vm._s(item.date))]):_c('div',[_vm._v(_vm._s(item.doe))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.role.update)?_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.role.delete)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }