import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#1e92f2', // #E53935
            secondary: '#ffa726', // #FFCDD2
            accent: colors.blue.lighten4, // #3F51B5
          },
        },
      },
});
