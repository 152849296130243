import Cast from "@/views/cast";
import CastCreate from "@/views/cast/create"
import CastEdit from "@/views/cast/edit"
import CastTrash from "@/views/cast/trash"

export default [
    {
        name: "Cast",
        path: "/cast",
        component: Cast,
        meta: {
            nav: {
                addToNav: true,
                navText: "Cast",
                header: "Cast",
                navIcon: "mdi-face-recognition",
            }
        },
    },
    {
        name: "CastCreate",
        path: "/cast/create",
        component: CastCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Cast > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "CastEdit",
        path: "/cast/edit",
        component: CastEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Cast > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "CastTrash",
        path: "/cast/trash",
        component: CastTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Cast > Trash",
                navIcon: "",
            }
        }
    }
]
