<template>
  <v-dialog v-model="openEdit" width="500">
    <v-card>
      <v-card-title class="subtitle-1 accent">Edit File</v-card-title>

      <v-card-text>
        <v-select
          v-model="form.path"
          :rules="rules.path"
          :items="folders"
          item-text="path"
          label="Move To"
        ></v-select>

        <v-text-field
          class="mt-2"
          v-model="form.file"
          :rules="rules.name"
          hint="Please enter name"
          label="Rename to*"
        ></v-text-field>

        <v-textarea
          class="mt-2"
          v-model="form.caption"
          hint="Please enter caption"
          label="Caption"
          rows="3"
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click.prevent="closeEdit">Cancel</v-btn>
        <v-btn color="green darken-1" text @click.prevent="editFile">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileAPI from "../api";
import config from "../config.js";

export default {
  props: ["openEdit", "editObj"],
  data() {
    return {
      folders: [],
      form: {
        path: "",
        file: "",
        caption: "",
      },
      rules: {
        path: [(v) => !!v || "File path is required"],
        name: [(v) => !!v || "File name is required"],
      },
    };
  },
  watch: {
    async openEdit(val) {
      if (val) {
        let { data } = await FileAPI.all(config.my_folder);
        this.folders = data.payload;
        this.form.path = this.editObj.path.split("/").pop();
        this.form.file = this.editObj.file;
        this.form.caption = this.editObj.caption;
      }
    },
  },
  methods: {
    closeEdit() {
      this.$emit("closeEdit");
    },
    async editFile() {
      try {
        this.spinner(true);
        let param = {
          file: `${this.editObj.path}/${this.editObj.file}`,
          new: `${config.my_folder}/${this.form.path}/${this.form.file}`,
          caption: this.form.caption,
        };
        let { data } = await FileAPI.update(param);
        this.snackbar({ status: true, message: data.message });
        this.spinner(false);
        this.$emit("change");
      } catch (err) {
        this.snackbar({ status: true, message: err.response.data.message });
        this.spinner(false);
      }
    },
  },
};
</script>
