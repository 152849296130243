<template>
  <v-card>
    <v-btn
      v-if="check"
      fab
      x-small
      style="position:absolute; right:5px; top: 5px; z-index:999;"
      dark
    >
      <v-icon class="ma-0 pa-0">mdi-check-bold</v-icon>
    </v-btn>
    <v-card-text class="px-3 py-0" @click="toggle">
      <v-img
        v-if="isImage(item.ext)"
        :src="getImage(item)"
        contain
        height="120"
      />
      <v-icon v-else size="120" class="ma-0 pa-0">mdi-file</v-icon>
      <div class="text-truncate" :data-value="getImage(item)">
        {{ item.file }}
      </div>
      <div class="text-truncate">{{ calculateSize(item.size) }}</div>
      <div class="text-truncate" v-if="item.width">
        {{ item.width }}x{{ item.height }}
      </div>
      <div v-else>-</div>
    </v-card-text>

    <v-card-actions class="pt-0">
      <v-btn class="primary" small @click.prevent="editItem(item)">Edit</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="error" small @click.prevent="deleteItem(item)"
        >Delete</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import config from "../config.js";
import FileAPI from "../api";

export default {
  props: ["item", "imgs", "openManager", "multiple", "selectedFiles"],
  data() {
    return {
      check: false,
    };
  },
  watch: {
    openManager(val) {
      if (val) {
        if (this.selectedFiles.length === 0) {
          this.check = false;
        }
      }
    },
    selectedFiles(files) {
      if (!this.multiple) {
        if (files[0] === this.getImage(this.item)) {
          this.check = true;
        } else {
          this.check = false;
        }
      }
    },
  },
  methods: {
    toggle() {
      this.check = !this.check;
      this.$emit("change", {
        file: this.getImage(this.item),
        caption: this.item.caption,
        status: this.check,
      });
    },
    isImage(ext) {
      return this.imgs.includes(ext);
    },
    getImage(item) {
      return config.file_path + item.path + "/" + item.file;
    },
    calculateSize(size) {
      if (!size) return;

      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      let self = this;
      this.confirm("Are you sure to delete this?").then(async () => {
        try {
          self.spinner(true);
          let { data } = await FileAPI.delete({
            file: item.path + "/" + item.file,
          });
          this.snackbar({ status: true, message: data.message });
          self.spinner(false);
          this.$emit("deleteItem");
        } catch (err) {
          self.spinner(false);
          this.$emit("deleteItem");
          console.log(err);
        }
      });
    },
  },
};
</script>
