<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <v-btn @click="$router.go(-1)" icon class=" mr-2"><v-icon class="cursor white--text">mdi-arrow-left</v-icon></v-btn>
          <span>Biography Trash</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_trashApps"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.photo="{ item }">
            <v-img class="ma-1" v-if="item.person.photo" :src="item.person.photo" width="50" height="50" />
            <v-icon class="ma-1" style="width:50px; height:50px; border: solid thin #ccc;" v-else large>mdi-account</v-icon>
          </template>
          <template v-slot:item.name="{ item }">
            <span>{{item.person.name}}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.restore" color="error" @click.prevent="restoreItem(item)">mdi-reload</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import BiographyApi from "@/api/biography"
export default {
  data() {
    return {
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Photo", value: "photo" },
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Assign To", value: "assign_to" },
        { text: "Published At", value: "published_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "action", sortable:false },
      ],
      search: "",
      application: []
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_trashCategories();
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Biography")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_trashApps: "biography/listTrash"
    })
  },
  methods: {
    async restoreItem(item) {
      let self = this
      this.confirm("Are you sure to restore this?").then(async () => {
        try{
          self.spinner(true)
          let {data} = await BiographyApi.restore(item.id)
          this.snackbar({status: true, message: data.message, color:"success"})
          await this.va_trashCategories()
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "biography/editObj",
      vm_queryObject: "biography/query"
    }),
    ...mapActions({
      va_trashCategories: "biography/trashApp"
    })
  }
};
</script>
