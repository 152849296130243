import MovieApi from '@/api/movie'

export default {
    namespaced: true,
    state: {
        list: {},
        listTrash: [],
        editObj: {},
        query: {
            search: '',
            itemsPerPage: 20
        },
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        editObj: state => state.editObj,
        query: state => state.query
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        editObj(state, data) {
            state.editObj = data
        },
        query(state, data) {
            state.query = data
        },
        reset(state) {
            state.list = {}
            state.listTrash = []
            state.editObj = {},
            state.query = {
                search: '',
                itemsPerPage: 15
            }
        }
    },
    actions: {
        async list({ commit }, queryString) {
            try{
                let {data} = await MovieApi.list(queryString);
                commit('list', data.payload);
            }catch(err){
                commit('list', {count: 0, rows: []})
            }
        },
        async trashApp({commit}){
            try{
                let {data} = await MovieApi.trash();
                commit('listTrash', data.payload);
            }catch(err){
                commit('listTrash', [])
            }
        }
    }
};
