import Profile from '@/views/user/profile';


export default [
    {
        name: 'Profile',
        path: '/profile',
        component: Profile,
        meta: {
            nav: {
                addToNav: false,
                navText: 'Profile',
                header: 'Profile',
                navIcon: '',
            }
        }
    }
]