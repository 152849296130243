import BiographyApi from '@/api/biography'
import LogApi from '@/api/log'

export default {
    namespaced: true,
    state: {
        list: {},
        listTrash: [],
        query: {
            search: '',
            itemsPerPage: 20
        },
        tab: 0
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        query: state => state.query,
        tab: state => state.tab
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        query(state, data) {
            state.query = data
        },
        tab(state,data){
            state.tab = data
        },
        reset(state) {
            state.list = {}
            state.listTrash = []
            state.query = {
                search: '',
                itemsPerPage: 15
            },
            state.tab = 0
        }
    },
    actions: {
        async list({ commit }, queryString) {
            try{
                let {data} = await BiographyApi.list(queryString);
                let log = await LogApi.list('biography')
                let payload = []
                for(let i=0; i<data.payload.rows.length; i++){
                    let match = log.data.payload.find(each => each.table_id==data.payload.rows[i].id)
                    if(match){
                        payload.push({...data.payload.rows[i], updating: true, updating_by: match.username, updating_id: match.user_id})
                    }else{
                        payload.push({...data.payload.rows[i], updating: false, updating_by: null, updating_id: null})
                    }
                }
                commit('list', {count: data.payload.count, rows: payload});
            }catch(err){
                commit('list', {count: 0, rows: []})
            }
        },
        async trashApp({commit}){
            try{
                let {data} = await BiographyApi.trash();
                commit('listTrash', data.payload);
            }catch(err){
                commit('listTrash', [])
            }
        }
    }
};
