import PersonalDetail from "@/views/personal-detail";
import PersonalDetailCreate from "@/views/personal-detail/create"
import PersonalDetailEdit from "@/views/personal-detail/edit"
import PersonalDetailTrash from "@/views/personal-detail/trash"

export default [
    {
        name: "PersonalDetail",
        path: "/personal-detail",
        component: PersonalDetail,
        meta: {
            nav: {
                addToNav: true,
                navText: "Personal Detail",
                header: "Personal Detail",
                navIcon: "mdi-card-account-details-outline",
            }
        },
    },
    {
        name: "PersonalDetailCreate",
        path: "/personal-detail/create",
        component: PersonalDetailCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Personal Detail > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "PersonalDetailEdit",
        path: "/personal-detail/edit",
        component: PersonalDetailEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Personal Detail > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "PersonalDetailTrash",
        path: "/personal-detail/trash",
        component: PersonalDetailTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Personal Detail > Trash",
                navIcon: "",
            }
        }
    }
]
