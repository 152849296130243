<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Upload Category</v-card-title>
      <v-card-text>
        <v-form ref="category" v-model="category.valid">
          <v-file-input label="Upload File" v-model="category.file" :rules="categoryRules.file" />
          <v-btn @click="uploadCategory">Upload</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-3">
      <v-card-title>Upload Biography</v-card-title>
      <v-card-text>
        <v-form ref="biography" v-model="biography.valid">
          <v-file-input label="Upload File" v-model="biography.file" :rules="categoryRules.file" />
          <v-btn @click="uploadBiography">Upload</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-3">
      <v-card-title>Upload Gossip</v-card-title>
      <v-card-text>
        <v-form ref="gossip" v-model="gossip.valid">
          <v-file-input label="Upload File" v-model="gossip.file" :rules="gossipRules.file" />
          <v-btn @click="uploadGossip">Upload</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import Import from "@/api/import"
import {mapGetters, mapMutations} from 'vuex';
export default {
  data(){
    return {
      category: {
        valid: false,
        file: null,
      },
      categoryRules: {
        file: [
          v => !!v || "File is required",
        ]
      },
      biography: {
        valid: false,
        file: null,
      },
      biographyRules: {
        file: [
          v => !!v || "File is required",
        ]
      },
      gossip: {
        valid: false,
        file: null,
      },
      gossipRules: {
        file: [
          v => !!v || "File is required",
        ]
      },
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      
    })
  },
  methods: {
    async uploadCategory(){
      if(this.$refs.category.validate()){
        try{
          this.spinner(true)
          let formData = new FormData();
          formData.append("file", this.category.file, this.category.file.name);
          let {data} = await Import.category(formData)
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    async uploadBiography(){
      if(this.$refs.biography.validate()){
        try{
          this.spinner(true)
          let formData = new FormData();
          formData.append("file", this.biography.file, this.biography.file.name);
          let {data} = await Import.biography(formData)
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    async uploadGossip(){
      if(this.$refs.gossip.validate()){
        try{
          this.spinner(true)
          let formData = new FormData();
          formData.append("file", this.gossip.file, this.gossip.file.name);
          let {data} = await Import.gossip(formData)
          this.snackbar({status: true, message: data.message, color:"success"})
          this.spinner(false)
        }catch(err){
          console.log(err)
          // this.snackbar({status: true, message:err.response.data.message, color:"error"})
          this.spinner(false)
        }
      }
    },
    ...mapMutations({
    })
  }
}
</script>
