import Person from "@/views/person";
import PersonCreate from "@/views/person/create"
import PersonEdit from "@/views/person/edit"
import PersonTrash from "@/views/person/trash"

export default [
    {
        name: "Person",
        path: "/person",
        component: Person,
        meta: {
            nav: {
                addToNav: true,
                navText: "Person",
                header: "Person",
                navIcon: "mdi-account",
            }
        }
    },
    {
        name: "PersonCreate",
        path: "/person/create",
        component: PersonCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Person > Create",
                navIcon: "",
            }
        }
    },
    {
        name: "PersonEdit",
        path: "/person/edit",
        component: PersonEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Person > Edit",
                navIcon: "",
            }
        }
    },
    {
        name: "PersonTrash",
        path: "/person/trash",
        component: PersonTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: "",
                header: "Person > Trash",
                navIcon: "",
            }
        }
    }
]
