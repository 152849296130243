import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/Layout';

/*
 *  register includes
 */
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import LeftNav from '@/components/LeftNav';

Vue.component('app-header', Header);
Vue.component('app-footer', Footer);
Vue.component('left-nav', LeftNav);

// get all routes from router directory
let routes = []
const pageOrder = ['dashboard', 'user', 'category', 'person', 'personal-detail', 'relation', 'timeline', 'biography', 'gossip', 'tag', 'genre', 'movie', 'season', 'cast', 'import', 'page401']
pageOrder.forEach(eachRoute => {
  routes.push(...require('./routes/' + eachRoute).default)
})

let page404 = require('./routes/page404').default
let Login = require('./routes/login').default

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      ...Login,
      {
        path: "/",
        component: Layout,
        meta: {
          nav: {
            addToNav: false
          },
        },
        children: [...routes],
  
      },
      ...page404
    ]
  })
  
  // create page title
  router.beforeEach((to, from, next) => {
    const documentTitle = 'Factceleb - ' + to.name;
    if (documentTitle) document.title = documentTitle;
  
    next();
  });
  
  export default router